//$base-path: '../';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import "hamburgers/hamburgers.scss";
@import "_config.scss";

$main: #fff;
$bg-light: #e5f7fc;
$bg: #cfedf5;
$bg-superlight: #f2fdff;
$facebook: #3969cf;
$twitter: #00a6e7;



@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

html{
  height: 100%;
  box-sizing: border-box;
  margin:0;
  padding:0;
}

body {
  overflow-x:hidden; 
  margin:0;
  padding:0;
  color:$main;
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.main-bg {
  position: absolute;
  transition: opacity .8s ease;
  opacity: 0;
  top: 0px;
  left: 0;
  width: 100%;
  bottom: 0;
  max-height: 1500px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.rear-bg {
  position: absolute;
  transition: opacity .8s ease;
  background-color:#fff;
  opacity: 1;
  top: 0px;
  left: 0;
  width: 100%;
  bottom: 0;
}

.main-bg.visible{
  opacity: 1;
}
.modal{

  .btn{
    background-color:transparent !important;
    border-radius:6px;
    border:3px solid #fff;
    span{
      color: #fff !important;
    }
  }

  .btn.logout{
    border:1px solid #fff;
    span{
      color: #fff !important;
      text-decoration: none;
    }
  }
}

.modal-backdrop.in{
  opacity: 0.5 !important;
}

.cropper-line, .cropper-point {
  display: none !important;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.header {
  height:100px;
  background: rgba(255,255,255,0.8);
  position:relative;
  z-index: 101;
  @media screen and (max-width : 991px) {
    z-index: 102;
    height:70px;
  }

  .border-bottom-bg{
    position:absolute;
    bottom:0px;
    height: 100%;
    width: 100%;
    background-position:center;
    background-image:url(../images/header-bg.jpg);
    background-size:100% 100%;
    background-repeat:no-repeat;

    @media screen and (max-width : 991px) {
      background-size:300% 100%;
    }
    @media screen and (max-width : 767px) {
      display:none;
    }
  }

  .mob-btn {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 101;
    display: none;

    @media screen and (max-width : 991px) {
      display:block;
    }

    button {
      outline: 0 !important;
      background: #212446;

      @media screen and (max-width : 991px) {
        background-color: rgba(255,255,255, 0.7);
      }
    }
  }

  .mob-menu.full {
    height: 100%;
  }

  .mob-menu {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(255,255,255);
    width: 100%;
    height: 0;
    opacity: 0;
    transition: opacity .2s ease;
    transition-delay: .2s;
    transform:translate(0%,0);
    z-index: -1;
    overflow: hidden;

    .links {
      display: block;
      position: absolute;
      min-width: 100%;
      top: 50%;
      width: 100%;
      left: 0;
      text-align: center;
      padding-bottom: 80px;
      transform: translate(0, -50%);

      .link {
        transform:scale(1,1);
        transition:transform .2s ease;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 13pt;
        font-weight: 600;
        float: none;
        color: #25673e;
        .inner {
          display: inline;
        }
      }

      .link:hover, .link.active {
        transform:scale(1.1,1.1);
        color: #25673e;    
      }

      .link.active {

      }

      a {
        text-decoration: none;
      }
    }

    .links.signs {
      padding-bottom: 0px;
      transform: translate(0, 0);
      position: absolute;
      top: initial;
      bottom: 5px;
      margin-top: 30px;

      .sign, .signed {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        margin-top: 4px;
      }
    }
  }

  .mob-menu.in {
    opacity:1;
    height: 100%;
    z-index: 100;
    transform:translate(0,0);

    .link.sign, .link.signed {

      color: #fff;
      background-color: #25673e;
    }
  }

  .links {
    z-index: 2;
    position: absolute;
    top: 44px;
    width: 50%;
    min-width: 385px;

    @media screen and (max-width : 991px) {
      display:none;
    }

    .link.double {
      margin-top: -10px;
      text-align: center;
    }

    .link {
      transition: color .3s ease;
      font-weight: 500;
      font-size: 11pt;
      color:#25673e;
      opacity:0.9;
      padding-left: 5px;
      padding-right: 5px;
      text-align: center;
      float: right;
      cursor: pointer;
      letter-spacing: 1px;
      font-weight: 600;
      
      .inner {
        padding:2px;
        padding-left: 4px;
        padding-right: 4px;
        border-radius:8px;
        border:1px solid transparent;
      }

      @media screen and (max-width : 1100px) {
        font-size: 10pt;
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .link.short {
      padding-left: 26px;
      padding-right: 26px;
    }

    .link.active {
      opacity:1;
      
      .inner {
        border:1px solid #25673e;
      }
    }

    .link:hover {
      opacity:1;
      .inner {
        border:1px solid #25673e;
      }
    }

    .sign-container.oneline{
      .link.sign, .link.signed {
        margin-top:22px;
        float: left !important;
        margin-right: 5px;
      }
    }

    .sign-container{
      float: left;
      position:absolute;
      right: 80px;
      margin-top: -26px;
    }

    .link.sign, .link.signed {
      border:none;
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      text-transform: capitalize;
      color: #25673e;
      text-overflow: ellipsis;
      background-color: #fff;
      border-radius: 10px;
      font-size:10pt;
      font-weight:500;
      padding-top: 6px;
      padding-bottom: 6px;
      letter-spacing: 1px;
      margin-left: 2px;
      margin-right: 2px;
      margin-top: -4px;
      font-weight: bold;
      float: none;
    }

    .link.sign{
      float: none !important;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4px;
      margin-top: 4px;
      width: 130px;
      @media screen and (max-width : 1100px) {
        width: 120px;
        font-size:9pt;
      }
    }

    .link.signed{ 
      width: 120px;
      @media screen and (max-width : 767px) {
        width: 100%;
      }
    }
  }

  .links.left {
    left: -120px;
    @media screen and (max-width : 1100px) {
      left: -90px;
    }

    .link {
      
    }
  }

  .links.right {
    right: -80px;
    @media screen and (max-width : 1100px) {
      right: -80px;
    }

    .link {
      
      text-align: center;
      float: left;
    }
  }

  a {
    text-decoration: none;
  }

  .center-area {
    position:absolute;
    left:50%;
    display:table;
    top:0;
    z-index: 2;
    text-align: center;
    transform:translate(-50%,0);

    .over {
      position: relative;
      z-index: 10;
    }

    .main-text {
      color: #fff;
      margin-top: 80px;
      font-size: 19pt;

      @media screen and (max-width : 991px) {
        font-size: 16pt;
      }
      @media screen and (max-width : 767px) {
        font-size: 16pt;
        margin-top: 47px;
      }
    }

    .sub-text {
      padding-top: 0px;
      font-size: 12pt;
      margin-top: -4px;
      font-weight: 500;
      color: #000;
      
      strong {
        font-size: 14pt;
      }
      
      @media screen and (max-width : 767px) {
        font-size: 11pt;
        strong {
          font-size: 12pt;
        }
      }
    }

    .triangle {
      position:absolute;
      top:2px;
      left:50%;
      z-index:1;
      width:190px;
      height:100px;
      margin-left:-95px;
      background-position:center;
      background-size:contain;
      background-repeat: no-repeat;
      background-image:url(../images/vallelata21/vallelata-logo.png);
      @media screen and (max-width : 991px) {
        
        height:70px;
      }
    }
  }
}

.page-wrapper {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

a {
  color:$main !important;
  text-decoration: none !important;
}

.container{
  position: relative;
  max-width: 1100px !important;
  width: 100% !important;
}


.fancy {
  line-height: 0.5;
  text-align: center;

  h1 {
    display: inline-block;
    position: relative;  
  }

  h1:before, h1:after {
    content: "";
    position: absolute;
    height: 22px;
    border-bottom:2px solid $main;
    top: 0;
    width: 140px;

    @media screen and (max-width : 767px) {
      height: 15px;
      width: 40px;
    }
  } 

  h1:before {
    right: 100%;
    margin-right: 15px;
  }

  h1:after {
    left: 100%;
    margin-left: 15px;
  }
}

section {
  font-family: Helvetica, Arial, sans-serif;
  border:none;
  margin: 0;
  outline: none;
}

.btn{
  border: none;
  outline: none;
  background: none;
}

.common.registration {
  background-color:transparent;
  text-align: center;
  padding-top:50px;
  padding-bottom:100px;
  color: #203c5b;

  .container-white {
    background-color:transparent;
  }

  .registration-form {

    .form-part {
      display: inline-block;
      position:relative;
      width: 50%;
      float: left;
      padding-left:5px;
      padding-right:5px;
      @media screen and (max-width : 767px) {
        width: 100%;
      }
    }

    .row {
      margin-bottom: 10px;
    }
    a{
      text-decoration: underline !important;
      color: #203c5b !important;
    }
    .privacy-text {
      margin-top: 20px;
      display: block;
      float: left;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    label {
      font-size: 9pt;
    }
    input.input {
      text-align: left;
      border:1px solid transparent;
      border-radius:3px;
      font-size: 13px;
      width: 100%;
      padding: 8px 8px;
      height: 36px;
      background-color:#b8c4d6;
      color: #203c5b;
      font-weight: 500;
    }

    input.input:focus, input.input:active {
      background-color:#fff;
    }

    .selectize-control {
      text-align: left;
      height: 36px;
    }

    .selectize-control.error {
      .selectize-input {
        border:2px solid red;
      }
    }

    .selectize-input {
      box-shadow:none;
      background-color:#b8c4d6;
      color: #203c5b;
      font-weight: 500;
      border:1px solid transparent;
    }

    .btn.cta {
      background-color:#203c5b;
      padding-top:6px;
      color: #fff !important;
      padding-bottom:6px;
      font-weight: 500;
      font-size: 14pt;
      width: 200px;
      border-radius:5px;
    }
  }
}


.common{

  .simple-btn  {
    background-color: #3a683d;
    color:#fff;
    border:none;
    border-radius: 6px;
    padding:8px;
    padding-left:17px;
    padding-right: 17px;
    font-size: 14pt;
  }

  .hidden-mobile {
    @media screen and (max-width : 767px) {
      display: none !important;
    }
  }
  .split-container {
    position:relative;
    overflow: hidden;
    height: 550px;
    padding-top:6px;
    padding-bottom: 6px;

    @media screen and (max-width : 767px) {
      height: inherit;
    }

    .split-side {
      position: relative;
      height: 100%;
      float:left;
      width: 50%;
      text-align: center;

      @media screen and (max-width : 767px) {
        width: 100%;
      }

      .split-content {
        position: absolute;
        top:50%;
        transform: translate(0, -50%);
        max-width: 590px;
        padding-right: 50px;
        padding-left: 50px;

        @media screen and (max-width : 1200px) {
          padding-right: 20px;
          padding-left: 20px;
        }

        @media screen and (max-width : 767px) {
          margin:auto;
          left:0;
          right:0;
        }
      }

      .split-title {
        color:#a8cb4c;
        font-family: "Chilanka", sans-serif;

        h3 {
          font-size: 32pt;
          @media screen and (max-width : 1200px) {
            font-size: 22pt;
          }
        }
      }

      .split-text {
        color:#3a683d;
        p{
          font-size: 17pt;
          line-height: 150%;

          @media screen and (max-width : 1200px) {
            font-size: 14pt;
          }
        }
      }
    }
    
    .first-side {
      .split-background {
        right:0;
        background-position: 100% 50%;
      }

      .split-content {
        right: 0px;
      }
    }
    .second-side {
      .split-background {
        left:0;
        background-position: 0 50%;

        
      }

      .split-content {
        left: 40px;
        right: 0px;
        @media screen and (max-width : 767px) { 
          right:0;
          left:0;
        }
      }
    }
    .split-background {
      width: 100%;
      position: absolute;
      max-width: 800px;
      height: 100%;
      top:0;
      background-repeat: no-repeat;
      background-size:cover;
    }

    .split-background.full {
      max-width: 100%;
    } 

    .split-side.filled {
      @media screen and (max-width : 767px) {
        position: relative;
        display: block;
      }

      .split-content {
        @media screen and (max-width : 767px) {
          display: block;
          top:10px;
          position: relative;
          transform: translate(0,0);
        }
      }
    }

    .split-side.empty {
      @media screen and (max-width : 767px) {
        height: 380px;
        position: relative;
        display: block;
      }
    }
  }

  .cover-area {
    position:relative;
    width: 100%;
    margin-top: -100px;
    z-index: 0;
    padding-top:100px;
    min-height: 350px;

    .background-image {
      position:absolute;
      top: 0;
      left: 0;
      bottom:0;
      right: 0;
      background-size:cover;
      background-position:50% 20%;
      background-repeat:no-repeat;
      background-image: url(../images/vallelata21/home-cover.jpg);
      @media screen and (max-width : 767px) {

      }
    }

    .text-area{
      color:#163c5e;
      text-align: center;
      position:relative;
      padding:40px;
      padding-bottom:30px;
      width: 100%;
      padding-left:10px;
      padding-right:10px;
      max-width:750px;
      margin-left:auto;
      margin-right:auto;

      h4 {
        margin-top: 0px;
        font-size: 12pt;
        font-weight: 600;
         @media screen and (max-width : 767px) {
          font-size: 10pt;
          margin-top: -17px;
         }
      }

      .main-title-image {
        position:relative;
        width: 100%;
        height: 100px;
        background-size:contain;
        background-position:50% 50%;
        background-repeat:no-repeat;
        @media screen and (max-width : 767px) {
          margin-top: -5px;
        }
      }
    }

  }

  .container-opaque {
    width: 100%;
    min-height: 800px;
  }

  .cta{
    padding-top: 30px;
    padding-bottom: 30px;
    .btn-cta{
      font-size: 13pt;
      font-weight: 500;
      color: #fff;
      border:3px solid #fff;
      border-radius: 5px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .cta.small{
    padding-top: 10px;
    margin-top:20px;
    padding-bottom: 10px;
    .btn-cta{
      font-weight: 500;
      color: #fff;
      border:3px solid #fff;
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .container-white{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: rgba(14,22,49,0.7);
    padding:20px;
    position: relative;
    padding-top: 10px;
    max-width: 650px !important;
    @media screen and (max-width : 767px) {
      background-color: transparent;
    }
  }
}

.title{
  text-align: center;  
  @media screen and (max-width : 767px) {
    h1{
      font-size:18pt;
    }
  }
}

.regolamento, .privacy{
  text-align: center;

  section{
    padding-left: 20px;
    padding-right: 20px;
  }

  h5{
    font-weight: 500;
  }
}

#map {
  .parco-container {
    border: 0px solid #25673e;
    .parco-descrizione{
      display: none;
    }
    .parco-nome{
      padding-bottom:10px;
    }

    .parco-header{
      display: none;
    }
  }
}

.parco.long-description {
  .parco-container {
    .parco-body {
      .parco-descrizione {
        max-height: 40px;
        position:relative;
        margin-bottom: 20px;
      }

      .leggidipiu {
        cursor: pointer;
        position:absolute;
        display: block;
        bottom:50px;
        z-index: 10;
        height: 40px;
        left: 0;
        right: 0;
        background:linear-gradient(to bottom, rgba(233,238,233,0), rgba(233,238,233,1));

        span {
          position:absolute;
          bottom: -15px;
          left: 0;
          width: 100%;
          text-align: right;
          padding-right:8px;
          font-weight: bold;
        }
        .more{
          display: block;
        }

        .close {
          display: none;
        }
      }
    }
  }
}

.parco.show-description {
  z-index: 1000;
  .parco-container {
    .parco-body {
      .parco-descrizione {
        max-height: 400px;
        position:relative;
      }

      .leggidipiu {
        background:transparent;
        .more{
          display: none;
        }

        .close {
          display: block;
        }
        
      }
    }
  }
}

.parco-container:hover {
  transform: scale(1.02,1.02);
  box-shadow:0 0 15px rgba(40,40,40,0.2);
}

.parco-container {
  transition:all .2s ease;
  transform: scale(1,1);
  box-shadow:0 0 8px rgba(40,40,40,0.05);
  position:relative;
  border: 0px solid #25673e;
  border-radius:8px;
  background-color:#fff;
  color: #25673e;
  padding-top:0px;

  .parco-image{
    border-radius:0px;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
    position:relative;
    top:0;
    left: 0;
    right: 0;
    z-index: 0;
    background-color: #25673e;
    background-position: center;
    background-size:cover;
    background-repeat:no-repeat;
    height:180px;
    @media screen and (max-width : 500px) {
      height:140px;
    }
  }
  .parco-header {
    display: none;
    height: 30px;
    position:absolute;
    top: -25px;
    left: -8px;
    right: -8px;
    border-radius:8px;
    z-index: 1;
    background-color:#25673e;
    color: #fff;
    text-align: center;
    font-size: 9pt;

    @media screen and (max-width : 500px) {
      font-size: 8pt;
    }

    .parco-header-title {
      position:absolute;
      left: 0%;
      text-align: center;
      top: 50%;
      margin-left: -20px;
      transform: translate(0%, -50%);
      width: 100%;
      font-weight: bold;
      @media screen and (max-width : 500px) {
        margin-left: -15px;
      }
    }


    .love-container {
      border:none;
      position:absolute;
      right: 10px;
      top: 50%;
      overflow: hidden;
      outline: none;
      background: transparent;
      transform: translate(0,-50%);
      @media screen and (max-width : 500px) {
        left:auto;
        right: 5px;
      }

      .love-icon {
        top: 50%;
        height: 25px;
        width: 25px;
        background-repeat: no-repeat;
        background-image:url(../images/vallelata21/heart-green-stroke.png);
        background-size: contain;
        background-position:center;
        opacity: 1;
        @media screen and (max-width : 500px) {
          height: 24px;
          width: 24px;
        }
      }

      .love-text{
        position:absolute;
        text-decoration:underline;
        top: 50%;
        right: 10px;
        transform: translate(0,-50%);
      }
    }
  } 

  .parco-nome.small{
    font-size: 8pt;
  }

  .parco-scuola.small{
    font-size: 8pt;
  }

  .parco-scuola {
    text-transform: uppercase;
    height: 34px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    span {
      padding-bottom: 2px;
      background: #f2cd12;
      padding-top: 2px;
      padding-left: 2px;
      padding-right: 2px;
    }
    b {
      @media screen and (max-width : 500px) {
        display: none;
      }
    }
  }
  .parco-nome{
    text-transform: uppercase;
    padding-top:5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 10pt;
    
    @media screen and (min-width : 501px) {
      height: 38px;
    }

    b {
      @media screen and (max-width : 500px) {
        display: none;
      }
    }
    span {
      
      @media screen and (max-width : 500px) {
        display: block;
        overflow: hidden;
        height: 40px;
      }
    }
    
  }

  .parco-regione{
    overflow: hidden;
    padding:5px;
    padding-top:10px;
    padding-bottom: 10px;
    font-size: 10pt;

    b {
      @media screen and (max-width : 500px) {
        display: none;
      }
    }
    span{
      @media screen and (max-width : 500px) {
        display: block;
      }
    }
  }

  .parco-body {
    padding:6px;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    overflow: hidden;
    //height: 200px;
    padding-bottom:40px;
    position:relative;
    background-color:#ffffff;
    z-index: 2;
    font-size: 10pt;

    @media screen and (max-width : 767px) {
      padding-bottom:60px;
    }
    .parco-descrizione{
      padding-top:4px;
      font-size: 9pt;
      line-height: 120%;
      overflow: hidden;
    }

    .leggidipiu {
      display: none;
    }
    .parco-link {
      position:absolute;
      bottom:10px;
      font-style: normal;
      font-weight: bold;
      font-size: 9pt;
      background:#25673e;
      border-radius: 4px;
      color:#ffffff;
      padding:4px;
      left: 5px;

      @media screen and (max-width : 767px) {
        bottom:30px;
      }
    }

    .lovers-container {
      position:absolute;
      right: 5px;
      bottom: 10px;
      color: #25673e;
      font-size: 10pt;
      font-weight: bold;
      text-align: right;

      .lovers-icon {
        position:absolute;
        right: 65px;
        top: -3px;
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-image:url(../images/vallelata21/heart-red-stroke.png);
        background-size: contain;
        background-position:center;

        @media screen and (max-width : 767px) {
          right: 63px;
          top: 0;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

.parchi-container {
  margin-bottom: 100px;
  padding-top: 20px;

  .parco {
    display: inline-block;
    width: 20%;
    padding:10px;
    position:relative;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;

    @media screen and (max-width : 991px) {
      width: 49%;
    }
  }
}

.contingency-header {
  .links {
    display: none;
  }
  .hamburger{
    display: none;
  }
}

.home.contingency {
  .cover-area {
    position:relative;
    width: 100%;
    margin-top: -100px;
    z-index: 0;
    padding-top:100px;
    min-height: 950px;

    @media screen and (max-width : 767px) {
      min-height: 750px;
    }

    .text-area {
      background-color: rgba(58,101,66,0.7) !important;
      margin-top: 100px;
      padding-left:60px;
      padding-right:100px;
      @media screen and (max-width : 767px) {
        margin-top: 0px;
        padding-left:4px;
        padding-right:4px;
      }

      h1 {
        text-align: left;
        margin-bottom: 0px;
        font-weight: 300;
        @media screen and (max-width : 767px) {
          font-size: 18pt;
          text-align: center;
        }
      }

      h2 {
        font-size: 40pt;
        margin-top: 5px;
        font-family: "Chilanka", sans-serif;
        margin-bottom: 20px;
        text-align: right;
        @media screen and (max-width : 767px) {
          font-size: 28pt;
          text-align: center;
          margin-bottom: 40px;
        }
      }

      h4 {
        font-size: 15pt;
        font-weight: 300;
        @media screen and (max-width : 767px) {
          font-size: 12pt;
        }
      }
    }

    .badge-puliamo {
      bottom: unset !important;
      left: unset !important;
      right: -40px !important;
      top: -40px !important;
    }

    .background-image {
      
      background-position:50% 100%;
      background-image: url(../images/vallelata21/contingency.jpg);
      @media screen and (max-width : 767px) {

      }
    }
  }
}


.home{

  .main-title {
    color: #25673e;
    margin-bottom: 20px;
    text-align: center;
    font-family: "Chilanka", sans-serif;
    h2 {
      font-size: 42pt;
      @media screen and (max-width : 767px) {
        font-size: 36pt;
      }
    }
  }

  .parco:nth-child(5) {
    @media screen and (max-width : 991px) {
      //display: none;
    }

  }

  .parco {
    .parco-header {
      display: none ;
    }
    .parco-descrizione {
      display: block;
    }
    .parco-nome {
      padding-bottom:0;
    }
    .parco-container {
      border:0px solid transparent;
    }
  }
  

  section#formazione {
    
    .split-background {
      background-image:url(../images/vallelata21/home-progetto-bg.jpg);
      @media screen and (max-width : 767px) {
        background-position: 100% 100%;
      }
    }

    .split-container {
      @media screen and (max-width : 767px) {
        padding-top:90px;
        padding-bottom: 60px;
      }
    }
  }

  section#premi {
    
    position: relative;
    .container-opaque {
      padding-left:20px;
      padding-right:20px;
      position:relative;
      padding-bottom:100px;
    }

    .split-container {
      
      .split-background {
        background-image:url(../images/vallelata21/home-premi-bg.jpg);
      }
      .split-side.first-side {
        background-color: #3a683d;
      }
      .split-side{
        text-align: left;
        .split-content {
          text-align: left;
        }

        .split-title {

          @media screen and (max-width : 767px) { 
            padding-top:50px;
          }
          h2 {
            color:#fff;
            font-size:26pt;
            margin-bottom: 10px;;
          }
          h3 {
            margin-top:0;
            color:#f2cd12;
            font-size:18pt;
            font-family: sans-serif;
            font-weight: 500;
          }
        }
      }

      .split-foreground {
        position:absolute;
        left:40px;
        top:40px;
        bottom:40px;
        width: calc(100% - 80px);
        max-width: 550px;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image:url(../images/vallelata21/home-premi-fg.jpg);
        @media screen and (max-width : 767px) { 
          max-width:1000px;
        }
      }

      .leaf {
        position:absolute;
        height: 60px;;
        width: 60px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      .leaf-top-left {
        background-image:url(../images/vallelata21/leafs-2.png);
        top:-40px;
        left: 20px;
        @media screen and (max-width : 767px) { 
          top:0px;
        }
      }

      .leaf-bottom-right {
        background-image:url(../images/vallelata21/leafs-1.png);
        bottom:10px;
        right: 40px;
        width: 80px;
        height:80px;
      }

      .split-text {

        @media screen and (max-width : 767px) { 
          padding-bottom:50px;
        }
        color:#fff;
        a {
          font-family: "Chilanka", sans-serif;
          text-decoration: underline  !important;
        }

        small {
          opacity: 0.8;
          font-size: 9pt;;
        }
        p {
          font-size: 12pt;
        }
        
      }
    }
  }

  section#piu-votati {
    background-color:#a8cb4c;
    position:relative;
    overflow: hidden;
    .container-opaque {
      max-width: 1100px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      padding-bottom:100px;

      @media screen and (max-width : 991px) {
        max-width: 480px;
        text-align: center;
      }
    }

    .parchi-container {
      padding-top: 0;
    }

    .main-title {
      margin-bottom: 0;
      margin-top: 50px;
      text-align: left;
      color:#fff;
      padding-left: 50px;
      position: relative;

      @media screen and (max-width : 767px) {
        text-align: center;
        padding-left: 0px;
      }

      .child-icon {
        position:absolute;
        left:0;
        width: 50px;
        height: 50px;
        top:-12px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image:url(../images/vallelata21/child-1.png);
      }
      h2 {
        font-size:28pt;

        @media screen and (max-width : 767px) {
          font-size:20pt;
        }
      }
    }

    .btn-discover {
      position:absolute;
      bottom:130px;
      z-index: 10;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 15pt;
      color: #fff;
      background:#3a683d;
      padding:6px;
      padding-right:30px;
      padding-left:30px;
      border-radius:8px;
      border:2px solid #fff;
      @media screen and (max-width : 767px) {
        bottom:80px;
      }
    }

    .parco {
      position:relative;
      z-index: 100;
    }
  }

  section#come-partecipare {
    text-align: left;
    position:relative;
    
    .background {
      position:absolute;
      bottom:0;
      left: 0;
      right: 0;
      height: 100%;
      background-image:url(../images/vallelata21/green-bg.png);
      background-size: contain;
      background-position:50% 50%;
      background-repeat:repeat;
    }
  }

  .discover-more{
    color: #25673e;
    font-weight: bold;
    text-decoration: underline;
    font-size: 13pt;
    margin-top: 0px;

    @media screen and (max-width : 767px) {
      margin-top: 30px;
    }
  }
  .container-opaque {
    min-height: 400px;
    background-position:center;
    background-size:cover;
    position:relative;
    text-align: center;
    padding-bottom:20px;
    .steps-area {
      max-width: 1200px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      position:relative;
      padding-top:20px;
    }

    .steps-title {
      padding-left: 50px;
      color: #fff;
      text-align: left;
      font-family: "Chilanka", sans-serif;
      @media screen and (max-width : 767px) {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
      }
      h2 {
        font-size: 32pt;
        margin-bottom:0;
        @media screen and (max-width : 767px) {
          font-size: 24pt;
        }
      }
    }
  }

  .steps {
    overflow: hidden;
    padding-top:0px;
    padding-bottom:25px;

    .step {
      display: inline-block;
      width: 32%;
      padding-bottom:32%;
      position:relative;

      @media screen and (max-width : 767px) {
        margin-bottom:0;
        height: 320px;
        width: 320px;
        padding-bottom: 0;
      }

      @media screen and (max-width : 350px) {
        height: 300px;
        width: 300px;
      }
    }

    .step-background {
      border:3px solid #f2cd12;
      position:absolute;
      left: 10%;
      right: 10%;
      border-radius:10px;
      top: 10%;
      bottom:10%;
      padding-bottom:80%;
      background-color:#f4f4f4;
    }

    .arrow {
      position:absolute;
      left: -20px;
      width: 40px;
      height: 100%;
      background-size:contain;
      background-repeat:no-repeat;
      background-position:center;
      background-image:url(../images/vallelata21/arrow-right.png);
      @media screen and (max-width : 767px) {
        display: none;
      }
    }

    .step-container{
      position:absolute;
      left: 0;
      right: 0;
      top: 10%;
      transform: translate(0, 0);
      text-align: center;
      padding-right:12px;
      padding-left:12px;
      line-height: 150%;
      font-size: 11pt;
      margin-bottom: 20px;
      color: #25673e;


      .step-text{
        max-width: 180px;
        margin-left: auto;
        margin-right: auto;
        font-size: 10pt;
        min-height: 70px;
        line-height: 130%;
        @media screen and (max-width : 900px) and (min-width : 768px) {
          font-size: 9pt;
        }
      }

      .step-image-icon{
        margin-top:50px;
        background-size: contain;
        background-position:center;
        background-repeat:no-repeat;
        width: 100%;
        height: 140px;
        margin-bottom: 5px;
        @media screen and (min-width : 1150px) {
          height: 180px;
        }
        @media screen and (max-width : 900px) and (min-width : 768px) {
          height: 100px;
        }
        @media screen and (max-width : 900px) and (min-width : 768px) {
          height: 100px;
        }
      }

      .step-title {
        font-family: Chilanka, sans-serif;
        position: absolute;
        color:#c53430;
        left:10%;
        right:10%;
        top:35px;
        transform: translate(0,-50%);
        font-size: 15pt;
        font-weight: bold;
        margin-bottom: 10px;

        @media screen and (max-width : 900px) and (min-width : 768px) {
          font-size: 11pt;
        }
      }
    }

    .step-container.one {
      .step-image-icon{
        background-image: url(../images/vallelata21/step-1.png);
      }
     
    }
    .step-container.two {
      .step-image-icon{
        background-image: url(../images/vallelata21/step-2.png);
      }
     
    }
    .step-container.three {
      .step-image-icon{
        background-image: url(../images/vallelata21/step-3.png);
      }
     
    }
  }

  .cover-area {
    position:relative;
    width: 100%;
    z-index: 100;
    min-height: 650px;

    @media screen and (max-width : 767px) {
      min-height: 550px;
    }

    .background-image {
      
      background-position:35% 25%;
      background-image: url(../images/vallelata21/home-cover.jpg);
      @media screen and (max-width : 767px) {

      }
    }
    .legambiente-logo {
      position:absolute;
      width: 120px;
      height: 120px;
      left: 40px;
      bottom:10px;
      background-size:contain;
      background-position:50% 50%;
      background-repeat:no-repeat;
      background-image: url(../images/vallelata21/legambiente-logo-testo.png);

      @media screen and (max-width : 767px) {
        left: 0;
        right:0px;
        margin-left: -80px;
        bottom:25px;
        width: 100%;
      }
    }
    .radio101-logo {
      position:absolute;
      width: 120px;
      height: 120px;
      right: 40px;
      bottom:10px;
      background-size:auto 80%;
      background-position:50% 50%;
      background-repeat:no-repeat;
      background-image: url(../images/vallelata21/101.png);

      @media screen and (max-width : 767px) {
        left: 80px;
        right:0;
        bottom:25px;
        width: 100%;
      }
    }

    .badge-puliamo {
      position:absolute;
      bottom: -110px;
      left: 50%;
      background-image:url(../images/vallelata21/badge-home.png);
      background-size: contain;
      background-position:center;
      height: 220px;
      margin-left: -110px;
      width: 220px;
      @media screen and (max-width : 767px) {
        bottom: -80px;
        height: 140px;
        width: 140px;
        margin-left: -70px;
      }
    }

    .text-area{
      max-width: 820px;
      padding-right:10px;
      padding-left: 10px;
      left: 0;
      right: 0;
      margin-top:75px;
      text-align: center;
      max-width: 1000px;
      color: #fff;
      position:absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      h4 {
        font-weight: bold;
        line-height: 150%;
        font-size: 14pt;
        margin-bottom: 0;
        @media screen and (max-width : 767px) {
          font-size: 11pt;
        }
        @media screen and (max-width : 500px) {
          font-size: 10pt;
          font-weight: normal;
        }
      }

      h1 {
        font-weight: normal;
        font-size: 48pt;
        margin-bottom: 0px;
        font-family: Chilanka, sans-serif;
        @media screen and (max-width : 991px) {
          font-size: 38pt;
          margin-bottom: 15px;
        }
        @media screen and (max-width : 767px) {
          font-size: 20pt;
          margin-bottom: 15px;
        }
      }

      .button-area {
        padding-bottom:50px;
        margin-top:-20px;
        .btn.cta{
          background-color:#25673e;
          padding:4px;
          border:2px solid #ddd;
          border-radius:8px;
          padding:5px;
          padding-left:15px;
          font-weight: normal;
          padding-right:15px;
          @media screen and (max-width : 767px) {
            font-size: 10pt;
          }
        }
        .btn.cta:hover{
          color: #fff;
        }
      }
    }
    
  }

  .home-title {
    height:80px;
    width:100%;
    background-image:url(../images/home-title.png);
    background-size:contain;
    background-position:center;
    background-repeat:no-repeat;
    margin-bottom:30px;
  }
  section.first {
    text-align: center;
    @media screen and (max-width : 767px) {
      margin-top:5px;
    }
    .logo-top{
      width: 180px;
    }

    .text{
      width: 100%;
      max-width: 480px;
      margin-right: auto;
      margin-left: auto;
      h5{
        font-size: 12pt;
        line-height:20px;
        font-weight: normal;
        b{
          font-weight: 500;
        }
        @media screen and (max-width : 767px) {
          font-size: 11pt;
          padding-left:10px;
          padding-right:10px;
        }
      }
    }

    .title {
      h1 {
        font-weight:bold;
        font-size:23pt;
        text-transform:uppercase;

        @media screen and (max-width : 767px) {
          font-size:14pt;
          font-weight:500;
        }
      }

      h2 {
        font-weight:bold;
        font-size:19pt;
        text-transform:uppercase;

        @media screen and (max-width : 767px) {
          font-size:12pt;
          font-weight:500;
        }
      }
    }

    
  }

  .vid {
    max-width: 550px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 0px;
    margin-top: 20px;
    height: 310px;
    padding: 5px;
    border:2px solid #a7bbd2;
    position: relative;
    overflow: hidden;

    @media screen and (max-width : 767px) {
      height:230px;
    }

    .embed-responsive {
      height: 100%;
      padding: 0;
      background-color: #1796ac;
    }

    iframe {
      background-color: #1796ac;
      height: 100%;
      width: 100%;
      border: none;
    }
  }

  .products-image{
    max-width: 500px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    height: 0;
    margin-top:30px;
    padding-bottom: 26%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/watch.png);
    @media screen and (max-width : 767px) {
      padding-bottom: 40%;
    }
  }

  section.second {
    padding-bottom: 20px;
    
    .characters{
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      display: table;
      @media screen and (max-width : 767px) {
        max-width:280px;
      }
      .character{
        height: 0;
        padding-bottom: 20%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (max-width : 767px) {
          padding-bottom: 30%;
        }
      }
    }
  }

  section.third{
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    @media screen and (max-width : 767px) {
      margin-bottom: 0px;
      margin-top: -15px;
      padding-left: 4px;
      padding-right: 4px;
      background-color: transparent;
    }
    .leaderboard-container{
      width: 100%;
      padding: 20px;
      padding-top: 30px;
      max-width: 420px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      border:4px solid #edf1d9;
      outline: 4px solid #9fc044;
      color: #414042;
      @media screen and (max-width : 767px) {
        background-color: rgba(255,255,255,0.6);
        max-width: 100%;
      }

      .title{
        font-weight: 500;
        font-size: 15pt;
      }

      .subtitle{
        text-decoration: underline;
        font-size: 12pt;
      }

      .player{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 100%;
        border-radius: 40px;
        height: 30px;
        overflow: hidden;
        background-color: #efdfd1;

        @media screen and (max-width : 767px) {
          font-size:10pt;
          padding-top: 6px;
        }

        .name{
          max-width: 60%;
          overflow: hidden;
          white-space: nowrap;
          text-transform: capitalize;
          float: left;
        }

        .points{
          float: right;
          max-width: 40%;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.home.il-nostro-progetto {
  
  .cover-area {
    height: 450px;
    min-height: 300px;

    .text-area {
      margin-top:0;
    }

    .legambiente-logo {
      right:50% !important;
      left: auto !important;
      transform:translate(50%,0);
    }
    .background-image {
      background-image:url(../images/vallelata21/home-progetto.jpg);
    }
  }

  .split-container {
    
    .split-side {
      .split-title {
        h3 {
          font-size:26pt;
          @media screen and (max-width : 1100px) {
            font-size: 18pt;
          }
        }
      }

      .split-text {
        p {
          font-size: 13pt;

          @media screen and (max-width : 1100px) {
            font-size: 11pt;
          }

          @media screen and (max-width : 767px) {
            padding-bottom: 40px;
          }
        }
      }
    }
  }

  section#legambiente {
    .split-background  {
      background-position: 80% 50%;
      background-image: url(../images/vallelata21/progetto-1.jpg);
    }
    .split-side {
      .split-content {
        @media screen and (min-width : 100px) {
          right: 60px;
        }
      }
    }

  }

  section#green {
    padding-bottom: 70px;
    @media screen and (max-width : 767px) {
      padding-bottom: 0;
    }
    
    .split-background  {
      background-position: 30% 50%;
      background-image: url(../images/vallelata21/progetto-2.jpg);
    }
    .second-side.split-side {
      background-color: #3a683d;
      background-image: url(../images/vallelata21/green-bg.png);
      background-position: center;
      background-repeat: repeat;
      
      .split-title {
        h3 {
          color: #f2cd12;
        }
      }
      .split-text {
        p {
          color:#fff;
          font-weight: 300;
        }
      }
    }
  }
}

.common.park {
  text-align: center;
  min-height:600px;
  background-color: #3a683d;
  background-image: url(../images/vallelata21/green-bg.png);
  background-position: center;
  background-repeat: repeat;
  margin-top: -100px;
  padding-top:100px;
  .park-container {
    padding-top:30px;
    max-width: 900px;
    margin:auto;
    width: 100%;
    padding-bottom:160px;
    .park-regione {
      padding-bottom:20px;
      h1 {
        font-family: Chilanka, sans-serif;
        font-size: 32pt;
        margin:0;
        @media screen and (max-width : 767px) {
          font-size: 24pt;
        }
      }
    }

    .park-image {
      width: 90%;
      position: relative;
      margin:auto;
      background: #3a683d;
      padding-bottom:45%;

      .park-background {
        background-size:cover;
        background-position: center;
        position:absolute;
        left: 0;
        right:0;
        top:0;
        bottom:0;
      }

      .lovers-container {
        position:absolute;
        background:rgba(255,255,255,0.7);
        padding:14px;
        padding-left: 80px;
        padding-right:20px;
        right: 0px;
        top: 0px;
        z-index: 10;
        color: #25673e;
        font-size: 10pt;
        font-weight: bold;
        text-align: right;
  
        .lovers-icon {
          position:absolute;
          right: 105px;
          top: 6px;
          height: 30px;
          width: 30px;
          background-repeat: no-repeat;
          background-image:url(../images/vallelata21/heart-red-stroke.png);
          background-size: contain;
          background-position:center;
  
        }
      }
    }

    .park-body {
      color: #3a683d;
      background-color:#699b28;
      background-image: url(../images/vallelata21/wood-bg.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size:cover;
      overflow: hidden;
      width: 100%;
      .park-body-header {
        background-color:rgba(255,255,255,0.5);
        padding:20px;
      }

      .vote-button {
        background:#3a683d;
        color:#fff;
        padding:6px;
        padding-left: 20px;
        padding-right: 20px;
        border:2px solid #fff;
        font-size: 15pt;
        border-radius: 6px;

        .lovers-icon {
          position:relative;
          float: left;
          height: 25px;
          width: 25px;
          margin-right: 10px;
          background-repeat: no-repeat;
          background-image:url(../images/vallelata21/heart-red-stroke.png);
          background-size: contain;
          background-position:center;
        }
      }

      .park-body-text {
        padding:20px;
        color:#fff;
        padding-bottom:40px;
        padding-top:40px;
        h2 {
          font-family: Chilanka, sans-serif;
          margin-bottom:20px;
        }

        p {
          line-height: 150%;
          display: block;
          max-width: 600px;
          margin:auto;
        }
      }

      .park-body-footer {
        font-family: Chilanka, sans-serif;
        background-color:rgba(255,255,255,1);
        padding:10px;
      }
    }
  } 
}

.common.vota {
  background-color: #3a683d;
  background-image: url(../images/vallelata21/green-bg.png);
  background-position: center;
  background-repeat: repeat;
  .cover-area {
    position:relative;
    width: 100%;
    margin-top: -100px;
    z-index: 0;
    padding-top:100px;
    padding-bottom:100px;
    margin-bottom:-100px;
    border-bottom:8px solid #fff;

    @media screen and (max-width : 767px) {
      min-height: 300px;
      padding-top:80px;
    }

    .background-image {
      
      background-position:50% 100%;
      background-image: url(../images/vallelata21/home-vota.jpg);
      @media screen and (max-width : 767px) {

      }
    }
    .text-area{
      max-width: 820px;
      padding-right:10px;
      padding-left: 10px;
      color: #fff;
      position:relative;
      padding-top: 50px;
      padding-bottom:0;

      h1 {
        font-family: Chilanka, sans-serif;
        font-weight: normal;
        font-size: 36pt;
        margin-bottom: 15px;
        color: #fff;
        @media screen and (max-width : 767px) {
          font-size: 28pt;
          margin-bottom: 10px;
          margin-top: -10px;
        }
      }

      .button-area {
        padding-bottom:50px;
        padding-top:0px;
        .btn.cta{
          background-color:#25673e;
          padding:4px;
          border:2px solid #ddd;
          border-radius:8px;
          padding:5px;
          padding-left:15px;
          font-weight: normal;
          padding-right:15px;
          @media screen and (max-width : 767px) {
            font-size: 10pt;
          }
        }
        .btn.cta:hover{
          color: #fff;
        }
      }
    }
    
  }
  .container-opaque{
    overflow: hidden;
    position:relative;
    min-height: 500px;
    #loading {
      position:absolute;
      z-index: 10;
      img {
        top:150px;
      }
    }
  }

  .steps-vota {
    overflow: hidden;
    padding-top:0px;
    padding-bottom:10px;

    @media screen and (max-width : 767px) {
      max-width: 420px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
   
    .step {
      display: inline-block;
      width: 24%;
      padding-bottom:24%;
      position:relative;

      @media screen and (max-width : 767px) {
        width: 49%;
        padding-bottom:49%;
        margin-top: -10px;
      }
    }

    .step-background {
      position:absolute;
      left: 10%;
      right: 10%;
      border-radius:10px;
      top: 10%;
      bottom:10%;
      padding-bottom:80%;
      background-color:#f4f4f4;
    }

    .arrow {
      position:absolute;
      left: -12px;
      width: 25px;
      height: 100%;
      background-size:contain;
      background-repeat:no-repeat;
      background-position:center;
      background-image:url(../images/vallelata21/arrow-right.png);
      @media screen and (max-width : 767px) {
        display: none;
      }
    }

    .arrow.mobile {
      @media screen and (max-width : 767px) {
        display: block;
      }
    }

    .step-container{
      position:absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      text-align: center;
      padding-right:12px;
      padding-left:12px;
      line-height: 150%;
      font-size: 11pt;
      margin-bottom: 10px;
      color: #25673e;
      @media screen and (max-width : 767px) {
        padding-right:5px;
        padding-left:5px;
        line-height: 130%;
      }

      .step-text{
        max-width: 180px;
        margin-left: auto;
        margin-right: auto;
        font-size: 10pt;
        min-height: 70px;
        line-height: 130%;
        @media screen and (max-width : 767px) {
          font-size: 8pt;
        }
      }

      .step-image-icon{
        background-size: contain;
        background-position:center;
        background-repeat:no-repeat;
        width: 100%;
        height: 65px;
        margin-bottom: 0px;
        @media screen and (max-width : 767px) {
          margin-top: 20px;
        }
      }
    }

    .step-container.one {
      .step-image-icon{
        background-image: url(../images/vallelata21/step-italia-green.png);
      }
     
    }
    .step-container.two {
      .step-image-icon{
        background-image: url(../images/vallelata21/step-2.png);
      }
     
    }
    .step-container.three {
      .step-image-icon{
        background-image: url(../images/vallelata21/step-vota.png);
      }
     
    }
    .step-container.four {
      .step-image-icon{
        background-image: url(../images/vallelata21/step-3.png);
      }
     
    }
  }

  .parchi-container {
    margin-top: 100px;
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width : 991px) {
      max-width: 480px;
      margin-top: 40px;
    }
  }

  .parco-header {display: none;}

  .posts-container {
    text-align: center;
    padding-bottom:150px;
    padding-right:10px;
    padding-left:10px;
    padding-top: 20px;
    position:relative;
    min-height: 600px;
    .posts-background {
      position:absolute;
      top: 150px;
      left: 0;
      right: 0;
      bottom:0;
      background-color:transparent;
    }

    .posts-slice-background {
      position:absolute;
      top: 60px;
      left: 0;
      right: 0;
      height: 200px;
      background-repeat:no-repeat;
      background-image: url(../images/vallelata21/vota-bottom-top.png);
      background-position:50% 0;
      background-size: 100% auto;
    }
    
    .filter-area.open {
      .filters-panel {
        height: auto;
      }
    }
    .no-results {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      color: #333;
    }
    .filter-area.effect {
      .filters-panel {
        opacity: 1;
        transform: translate(0,100%);
      }
    }
    .filter-area {
      text-align: center;
      position:relative;
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      z-index: 15;
      .filter-btn {
        background-color:#a8ca4e;
        color: #fff;
        border:2px solid #fff;
        font-weight: normal;
        font-size: 16pt;
        width: 100%;
        max-width: 500px;
        border-radius:6px;
        outline: none;
        padding-top:6px;
        padding-bottom:6px;
        position:relative;
        z-index: 10;
        @media screen and (max-width : 767px) {
          font-size: 12pt;
        }
      }

      .filters-panel {
        max-height: 300px;
        border-bottom:2px solid #25673e;
        transition:all .3s ease;
        opacity: 0;
        height: 0;
        overflow: hidden;
        max-width: 500px;
        position:absolute;
        bottom:0;
        left:4px;
        right: 4px;
        margin-left: auto;
        margin-right: auto;
        transform: translate(0,90%);
        text-align: center;
        border-left:2px solid #25673e;
        border-right:2px solid #25673e;
        overflow: hidden;
        overflow-y: auto;
        border-radius:2px;

        .filter:hover {
          background-color:#25673e;
          color: #fff;
        }

        .filter {
          position:relative;
          padding-bottom:15px;
          padding-top: 15px;
          height: 50px;
          font-weight: 700;
          width: 100%;
          border:none;
          color:#25673e;
          display: block;
          background-color:#fff;
          font-size: 9pt;
          border-bottom:2px solid #25673e;
          @media screen and (max-width : 767px) {
            font-size: 9pt;
          }
          @media screen and (max-width : 380px) {
            font-size: 8pt;
          }

          span {
            position:absolute;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;
            transform: translate(0,-50%);
          }
        }
      }
    }

    .post-container.voted {
      .love-container {
        width: 38px;
        .love-icon {
          opacity: 1;
          background-image:url(../images/vallelata21/heart-red-stroke.png);
        }
        .love-text{
          display: none;
        }
      }
    }

    .post-container.read-more {
      .more-container {
        display: block;
      }
    }

    .post-container.read-all {
      .post-text {
        max-height: 5000px;
      }
    }
  }

  .input-area{

    .input-container {

      margin-top: 30px;
      margin-bottom: 40px;
      position:relative;

      .esempio-container.open{
        display: block;
      }

      .esempio-container{
        display: none;
        position:absolute;
        right: 0;
        top: 0;
        padding:15px;
        padding-top:30px;
        z-index: 10;
        background-color:#163c5e;
        width: 100%;
        max-width: 320px;
        border-radius:5px;

        .esempio-close{
          position:absolute;
          right: 6px;
          top: 6px;
          border:none;
          background:transparent;
          color: #fff;
          font-weight: 700;
          z-index: 10;
        }

        .esempio-text {
          line-height: 150%;
          font-size: 8pt;
          position:relative;
          color: #fff;
        }
      }
      .input-title{

        position:absolute;
        height: 30px;
        top: -26px;
        padding:5px;
        font-weight: 600;
        border-radius:4px;
        background-color:#fff;
      }
      .max-char {
        position:absolute;
        bottom:8px;
        right: 8px;
        font-size: 8pt;
        z-index: 10;
        text-decoration:underline;
      }
      textarea.error {
        border:2px solid red;
      }
      textarea {
        position:relative;
        box-shadow:none;
        border:none;
        border-radius:4px;
        padding:10px;
        width: 100%;
      }
    }
    .button-container{
      text-align: right;
      @media screen and (max-width : 767px) {
        text-align: center;
      }
    }
    button.btn {
      background-color:#0a3659;
      color: #fff;
      padding:20px;
      padding-top:8px;
      padding-bottom:8px;
      border-radius:4px;
      margin-top: 0;
      @media screen and (max-width : 767px) {
        width: 100%;
      }
    }
  }

  .loose-container.win {

    max-width: 500px;

    .loose-image {
      height: 150px;
      width: 100%;
      background-size: contain;
      background-position:center;
      background-repeat:no-repeat;
      background-image:url(../images/failadifferenza/win-page.png);
    }
  }

  .loose-container {
    text-align: center;
    padding-top:100px;
    padding-bottom:200px;
    width: 100%;
    max-width: 400px;
    margin-left:auto;
    margin-right: auto;
    padding-right:15px;
    padding-left:15px;

    .loose-title {
      font-weight: 700;
      font-size: 22pt;
      margin-bottom: 30px;
      @media screen and (max-width : 767px) {
        font-size: 15pt;
      }
    }
    .loose-text{
      line-height: 150%;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .btn.cta {
      background-color:#0a3659;
      color: #fff;
      padding:0px;
      font-size: 10pt;
      padding-top:8px;
      padding-bottom:8px;
      border-radius:4px;
      margin-top: 15px;
      width: 220px;
      @media screen and (max-width : 767px) {
        width: 100%;
      }
    }
  }
}

.common.tutti-i-parchi.is-map {
  margin-bottom: -90px;
  .container-opaque, .posts-container{
    //max-width: 100% !important;
    //padding:0 !important;
  }

  .container-opaque {
    
    padding-left:20px;
    padding-right:20px;
  }

  #map {
    margin-top:50px;
  }
}

.common.tutti-i-parchi {

  .container-opaque {
    background-color: #3a683d;
    background-image: url(../images/vallelata21/green-bg.png);
    background-position: center;
    background-repeat: repeat;
  }

  #loading {
    top: 10px;
    z-index: 10001;
  }

  .full-item-container.active {
    display: block;
  }

  .full-item-container {
    position:fixed;
    top: 0%;
    bottom:0;
    transform: translate(0,0%);
    left: 0px;
    right: 0px;
    background-color:rgba(0,0,0,0.8);
    z-index: 1000;
    display: none;

    .close-icon {
      padding-top:10px;
      padding-bottom: 10px;
      padding-left:20px;
      padding-right:20px;
      position:absolute;
      right: -20px;
      top: -40px;
      font-weight: bold;
      color: #fff;
    }

    .listing-item {
      position:absolute;
      left: 10px;
      margin-left: auto;
      margin-right: auto;
      max-width:300px;
      right: 10px;
      top: 50%;
      opacity: 1;

      transform: translate(0%,-50%);

      .parco-header {display: none;}

      .parco-nome {
        margin-bottom: 0px;
      }
    }
  }

  #map {
    background:rgb(233,238,233);
    width: 100%;
    height: 1000px;

    @media screen and (max-width : 767px) {
      height: 600px;
    }

    .map-item-container.active {
      z-index: 1002;
      .listing-point-halo {
        opacity: 0;

      }

      .listing-point {
        background-image:url(../images/vallelata21/map-pin-active.png);
        transform: scale(1.3) translate(-40%, -85%);
      }

      .listing-item {
        opacity: 1;
        height: initial !important;
        transform: translate(50%,-7%);

        .parco-container {
          transform:scale(1,1) !important;
        }

        .parco-nome {
          line-height: 115%;
          margin-bottom: 0px;
        }

        .parco-scuola {
          line-height: 115%;
        }
      }
    }

    .map-item-container.active-bottom {
      
      .listing-item {
        
        transform: translate(50%,110%);
      }
    }

    .map-item-container{
      
      .listing-point {
        position:absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -100%);
        width: 30px;
        height: 30px;
        background-position: center;
        background-size:contain;
        background-repeat:no-repeat;
        background-image:url(../images/vallelata21/map-pin.png);
        border-radius:20px;
        z-index: 99;
        transition: transform .2s ease;
        transform-origin: 50% 50%;
      }

      .listing-point-halo {
        z-index: 100;
        opacity: 0;
        transition: opacity 0.8s ease;
        position:absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        width: 25px;
        height: 25px;
        background-color:rgba(0,88,144,0.5);
        border-radius:20px;

      }

      .listing-item {
        z-index: 1001;
        transition: opacity .3s ease;
        width: 200px !important;
        opacity: 0;
        height: 0 !important;
        background: #fff;
        border-radius:10px;
        overflow: hidden;
        position: absolute !important;
        right: 50%;
        transform: translate(50%, 10px);
        bottom: 25px;
        box-shadow: 0 0px 4px rgba(0,0,0,0.4);

        .listing-price {
          padding-left:3px;
          padding-right:3px;
        }
        .listing-values {
          padding-left:3px;
          padding-right:3px;
        }
      }

      .cta-button {
        position:relative;
        background-color:#255c90;
        color: #fff;
        border:none;
        box-shadow:none;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom:6px;
        padding-top:6px;
        margin-top: 10px;
      }
    }
  }
  .header-container {
    color: #fff;
    .list-map-toggle {
      float: left;
      padding-left:30px;

      @media screen and (max-width : 767px) {
        float: none;
        margin-bottom: 20px;
        margin-left: auto;
        text-align: center;
        margin-right: auto;
      }

      .btn {
        position:relative;
        .image {
          width: 40px;
          height: 40px;
          position:absolute;
          left: -30px;
          top: 50%;
          transform: translate(0, -50%);  
          background-position:center;
          background-size:contain;
          background-repeat:no-repeat;

        }
        .text {
          font-size: 13pt;
          color: #fff !important;
          text-decoration: underline !important;
        }
      }
      .btn.map {
        .image {
          background-image:url(../images/vallelata21/step-italia.png);
        }
      }
      .btn.list {
        .image {
          background-size:60% 60%;
          background-image:url(../images/vallelata21/list-btn.png);
        }
      }
    }
    .filters-container {
      float: right;

      @media screen and (max-width : 767px) {
        float: none;
      }

      .filter-title {
        display: inline-block;
         @media screen and (max-width : 767px) {
          font-size:10pt;
          margin-right: 0;
        }
      }

      .filter-area {
        width: 180px;
        display: inline-block;
        @media screen and (max-width : 767px) {
          width: 120px;
        }

        @media screen and (max-width : 340px) {
          width: 105px;
        }
      }
    }
  }
  .parco {
    .parco-header {
      display: none ;
    }
    .parco-container{
      border:0px solid transparent;
    }

  }
  .cover-area {
    position:relative;
    width: 100%;
    margin-top: -100px;
    z-index: 0;
    margin-bottom: 0px;
    padding-top:100px;
    min-height: 450px;


    @media screen and (max-width : 767px) {
      min-height: 300px;
      padding-top:20px;
    }

    .background-image {
      
      background-position:50% 100%;
      background-image: url(../images/vallelata21/home-gallery.jpg);
      @media screen and (max-width : 767px) {

      }
    }
    .text-area{
      max-width: 820px;
      padding-right:10px;
      padding-left: 10px;
      color: #fff;
      position:relative;
      padding-top: 80px;

      h4 {
        font-weight: normal;
        line-height: 150%;
        font-size: 14pt;
        @media screen and (max-width : 767px) {
          font-size: 10pt;
        }
      }

      h1 {
        font-family: Chilanka, sans-serif;
        font-weight: normal;
        font-size: 46pt;
        margin-bottom: 15px;
        @media screen and (max-width : 767px) {
          font-size: 40pt;
          margin-bottom: 25px;
        }
      }

      .button-area {
        padding-bottom:50px;
        padding-top:0px;
        .btn.cta{
          background-color:#25673e;
          padding:4px;
          border:2px solid #ddd;
          border-radius:8px;
          padding:5px;
          padding-left:15px;
          font-weight: normal;
          padding-right:15px;
          @media screen and (max-width : 767px) {
            font-size: 10pt;
          }
        }
        .btn.cta:hover{
          color: #fff;
        }
      }
    }
    
  }
  .container-opaque{
    overflow: hidden;
    position:relative;
    #loading {
      position:absolute;
      z-index: 10;
      img {
        top:150px;
      }
    }
  }
  .parchi-container {
    max-width: 1200px;
    width: 100%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width : 991px) {
      max-width: 480px;
      margin-top: 40px;
    }
  }
  .posts-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-bottom:150px;
    padding-right:10px;
    padding-left:10px;
    padding-top: 20px;
    .filter-area.open {
      .filters-panel {
        height: auto;
      }
    }
    .no-results {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      color: #333;
    }
    .filter-area.effect {
      .filters-panel {
        opacity: 1;
        transform: translate(0,100%);
      }
    }
    .filter-area {
      text-align: center;
      position:relative;
      width: 100%;
      max-width: 500px;
      margin-left: 10px;
      z-index: 15;
      @media screen and (max-width : 767px) {
        margin-left: 3px;
      }
      .filter-btn {
        background-color:#a8cb4c;
        color: #fff;
        font-weight: 400;
        font-size: 12pt;
        width: 100%;
        max-width: 500px;
        border-radius:6px;
        border:none;
        outline: none;
        padding-top:6px;
        padding-bottom:6px;
        position:relative;
        z-index: 10;
        @media screen and (max-width : 767px) {
          font-size: 9pt;

        }
      }

      .filters-panel {
        max-height: 300px;
        border-bottom:2px solid #25673e;
        transition:all .3s ease;
        opacity: 0;
        height: 0;
        overflow: hidden;
        max-width: 500px;
        position:absolute;
        bottom:0;
        left:4px;
        right: 4px;
        margin-left: auto;
        margin-right: auto;
        transform: translate(0,90%);
        text-align: center;
        border-left:2px solid #25673e;
        border-right:2px solid #25673e;
        overflow: hidden;
        overflow-y: auto;
        border-radius:2px;

        .filter:hover {
          background-color:#25673e;
          color: #fff;
        }

        .filter {
          position:relative;
          padding-bottom:15px;
          padding-top: 15px;
          height: 50px;
          font-weight: 700;
          width: 100%;
          border:none;
          color:#25673e;
          display: block;
          background-color:#fff;
          font-size: 9pt;
          border-bottom:2px solid #25673e;
          @media screen and (max-width : 767px) {
            font-size: 9pt;
          }
          @media screen and (max-width : 380px) {
            font-size: 8pt;
          }

          span {
            position:absolute;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;
            transform: translate(0,-50%);
          }
        }
      }
    }

    .post-container.voted {
      .love-container {
        width: 38px;
        .love-icon {
          opacity: 1;
          background-image:url(../images/failadifferenza/loved.png);
        }
        .love-text{
          display: none;
        }
      }
    }

    .post-container.read-more {
      .more-container {
        display: block;
      }
    }

    .post-container.read-all {
      .post-text {
        max-height: 5000px;
      }
    }
  }
}

.common.premi {
  color:#163c5e;
  .prize-container {
    text-align: center;
    padding-top:100px;
    padding-bottom:200px;
    width: 100%;
    max-width: 550px;
    margin-left:auto;
    margin-right: auto;
    padding-right:15px;
    padding-left:15px;

    .prize-title {
      font-weight: 700;
      font-size: 22pt;
      margin-bottom: 30px;
      @media screen and (max-width : 767px) {
        font-size: 15pt;
      }
    }
    .prize-text{
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 30px;
    }

    .prize-image {
      height: 150px;
      width: 100%;
      background-size: contain;
      background-position:center;
      background-repeat:no-repeat;
      background-image:url(../images/failadifferenza/premi.png);
    }
  }
}

.code-uploader {

  .error-messages {
    font-weight: 500 !important;
    color: red !important;
    font-size: 12pt;
  }

  .border-bg{
    background-image: url(../images/icon-border-green.png);
  }

  section.first {
    margin-bottom:0px;

    @media screen and (max-width : 767px) {
      margin-bottom:0px;
      padding:0;
    }

    .first-container.small{
      min-height:300px;
      padding-top:30px;

      .watch{
        max-width: 500px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        height: 0;
        margin-top:30px;
        height:140px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/watch.png);
        @media screen and (max-width : 767px) {
          height:140px;
        }
      }

      .image{
        width: 100px;
        margin-top:20px;
        margin-bottom: 30px;
        height:100px;
        margin-left: auto;
        margin-right: auto;
        background-image:url(../images/looser.png);
        background-position:center;
        background-size:cover;
      }

      .title{
        width: 100%;
        display: table;
        text-align: center;

        h1{
          width: 100%;
          font-size: 22pt;
          font-weight: 900;
          color: #fff;
        }
      }

      .btn.quiz-help-btn{
        margin-bottom: 20px;
      }

      .text{

        max-width: 580px;

        h4{
          font-weight:400;
          font-size: 12pt;

          @media screen and (max-width : 767px) {
            font-size: 10pt;
          }
        } 

        b{
          font-weight: bold;
        }       
      }
    }

    .first-container{
      padding-bottom:30px;

      @media screen and (max-width : 767px) {
        min-height: 600px;
        margin-top: 0;
        padding:10px;
      }
    }

    .row{
      margin-left: 0;
      position:relative;
      margin-right: 0;
    }

    .title {
      text-align: center;
      margin-bottom: 4px;
      font-size: 16pt;
      font-weight: 500;
      width: 100%;
      display: table;
      
      h1{
        margin-top: 20px;
        width: 100%;
        text-transform:uppercase;
        font-weight: bold;
        color: #fff;
        font-size: 20pt;

        @media screen and (max-width : 767px) {
          font-size: 12pt;
          br{
            display: none;
          }
        }
      }

      h3{
        color: #372b20;
        margin-top: 0px;
        font-size: 12pt;

        strong{
          text-transform: uppercase;
          font-weight:  900;
        }

        b{
          font-weight:  900;
        }

        @media screen and (max-width : 767px) {
          font-size: 10pt;
        }
      }
    }

    .steps {
      padding-bottom: 20px;
    }

    .cta.small {

      margin-top:5px;
    }

    .cta {
      padding-top:0;
    }

    .step {
      position:relative;
      z-index: 1;
      margin-right: auto;
      margin-left: auto;
      margin-top: 0px;
      padding-bottom: 10px;

      .main-title{
        .number {
          font-size: 32pt;
        }
      }
      

      .code-input {

        h5 { 
          font-weight: 400;
        }

        h4 {
          margin-top: 0;
          font-weight: 400;
          font-size: 12pt;
          margin-bottom: 15px;
        }

        h5.gray {
          color: #999;
          text-transform:none;
          margin-top: 10px;
        }

        .toexample {
          display: block;
          font-weight: 500;
          margin-top: 20px;
        }

        #file-input {
          position: absolute;
        }

        .name-file {
          font-size: 9pt;
          padding: 3px;
          width: 40%;
          background-color: #ccc;
          margin-right: auto;
          margin-left: auto;
          border-radius: 10px;
          color: #999;
          overflow: hidden;
          margin-top: 5px;

          @media screen and (max-width : 767px) {
            width: 70%;
          }
        }

        .name-file.error {
          background: #ff5555;
          color: #000 !important;
        }

        .inputs{

          .col-xs-3, .col-xs-1 {
            padding-left: 0px;
            padding-right: 0px;
          }

          .dot {
            float: left;
            width: 12px;
            text-align: center;
            font-weight: 500;
            padding-top: 15px;
          }

          .col-xs-3{
            width: 20%;
          }

          .col-xs-1 {
            width: 13.33%;
          }

          .input {
            position: relative;
            width:150px;
            font-weight: 500;
            outline: none;
            padding: 5px;
            padding-left: 2px;
            color:#000;
            padding-right: 2px;
            text-align: center;
            border: none;
            border-radius: 5px;
          }
        }

        .inputs.second{ 

          .input {
            margin-left: auto;
            margin-right: auto;
            border-radius: 10px;
            width: 200px;
            @media screen and (max-width : 767px) {
              width: 100%;
              margin-left: 0%;
            }
          }
        }
      }
    }

    .step.second {
      padding-top: 5px;
      margin-bottom: 5px;
      
      .code-input {

        h4 {
          margin-bottom: 5px;
        }
      }
    }

    .loose {

      padding-bottom: 80px;
      padding-left: 70px;
      padding-right: 70px;

      @media screen and (max-width : 767px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .image {
        height: 80px;
        width: 80px;
        margin-right: auto;
        margin-left: auto;
        background-size: auto 120%;
        background-position: center;
      }


      .image.one {
        background-image: url(../images/smile-face.png);
      }
    }

    .win {
      .image.winner {
        background-image: url(../images/win-gift.png);
      }

    }

    .esempio {

      text-decoration: underline ;
    }

    .container {

      .switch {

        .col{
          outline: none;
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 0;
          margin-bottom: 0;

          .topborder{
            position: absolute;
            top: 0;
            left: 10%;
            width: 80%;
            height: 5px;
            background-size: auto 138%;
            background-position: center;
            background-image: none !important;
            border-top: 1px solid #ee5468; 
          }

          .left-border {
            left: initial;
            right: 0;
            background-image: none;
            border-right: 1px solid #ee5468; 
          }
        }

        .image {
          height: 80px;
          width: 80px;
          margin-right: auto;
          margin-left: auto;
          background-size: auto 100%;
          background-position: center;
        }

        .image.three {
          background-image: url(../images/code-3.png);
        }

        .image.two {
          background-image: url(../images/code-2.png);
        }
      }
    }
  }
}

.videos {

  .container-white{
    
    max-width: 700px !important;
    
  }

  section.first{
    color: #fff;
    text-align: center;

    .ep-title{
      color: #fff;
      font-size: 20pt;
      width: 100%;
      display: table;
      font-weight: 400;
      margin-top: 0px;
      text-align: center;
      @media screen and (max-width : 400px) {
        font-size: 13pt;
      }
    }

    .title {
      h1{
        font-weight:bold;
        @media screen and (max-width : 400px) {
          font-size: 16pt;
        }
      }
    }

    .main-vid-container{

      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 360px;
      position: relative;

      @media screen and (max-width : 767px) {
        height:230px;
      }

      .arrow{
        cursor: pointer;
        border: none;
        background: transparent;
        position: absolute;
        top: 50%;
        height: 50px;
        width: 50px;
        margin-top: -25px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 10;
      }

      .arrow.left{
        left: -18px;
        background-image: url(../images/arrow-left-icon.png);
      }

      .arrow.right{
        right: -18px;
        background-image: url(../images/arrow-right-icon.png);
      }
    }

    .main-vid{
      margin-top: 30px;
      max-width: 100%;
      width: 100%;
      max-width: 700px;
      margin-right: auto;
      margin-left: auto;
      border-radius: 0px;
      height: 100%;
      padding: 5px;
      border:2px solid rgba(255,255,255,0.7);
      position: relative;
      overflow: hidden;

      .embed-responsive {
        height: 100%;
        padding: 0;
      }

      .video{
        cursor: pointer;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
        padding: 3px;

        .screen{
          background-color:transparent !important;
        }

        .play-icon{
          display: none;
        }
      }

      iframe {
        background-color: #1796ac;
        height: 100%;
        width: 100%;
        border: none;
      }
    }

    .video{
      cursor: pointer;
      position: absolute;
      top: 35px;
      height: 90px;
      width: 94%;
      left: 3%;
      padding: 3px;
      
      .screen{
        width: 100%;
        height: 100%;
        background-color: #1796ac;
      }

      .play-icon{
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0.7;
        margin-left: -30px;
        margin-top: -30px;
        height: 60px;
        width: 60px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../images/play-icon.png);
      }
    }

    .video:hover{
      .play-icon{
        opacity: 1;
      }
    }

    .previews{
      position: relative;
      width: 100%;
      padding-left:10px;
      padding-right:10px;
      margin-top: 40px;
      height: 160px;  
      #loader{
        position: absolute;
        left: 50%;
        margin-left: -100px;
        width: 200px;
        height: 150px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/loader.gif);
      }

      .preview.disabled{
        opacity: 0.5;
      }

      .preview{
        display: inline-block;
        float: left;
        width: 20%;
        padding: 2px;
        box-sizing:border-box;
        position: relative;   
        height: 100%;

        @media screen and (max-width : 767px) {
          width: 50%;
        }

        @media screen and (min-width : 768px) {
          margin-left: 0 !important;
        }

        .video{
          border: 2px solid rgba(255,255,255,0.7);
        }

        .play-icon{
          height: 40px;
          width: 40px;
          margin-left: -20px;
          margin-top: -20px;
        }

        .title{
          color: #fff;
          font-size: 13pt;
          font-weight: 400;
        }
      }
      .preview.empty{
        opacity: 0;
        cursor: auto;
      }
    }
  }
}

.come-partecipare {

  h1 {
    font-weight:bold;
    margin-bottom:40px;
  }
  .how{
    margin-bottom: 40px;

    .image{
      max-width: 500px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      height: 65px;
      margin-top: 20px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .text{
    text-align: center;
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;

    a{

      h5{
        text-decoration: underline;
      }
    }

    h5{
      font-size: 12pt;
      line-height: 20px;
      font-weight: normal;
      b{
        font-weight: 500;
      }
      @media screen and (max-width : 767px) {
        font-size: 10pt;
        padding-left:10px;
        padding-right:10px;
      }
    }
  }
}

.premi {

  section{
    overflow: hidden;
  }

  .img-title{
    position: absolute;
    left: 0;
    top: 0px;
    margin-top: 10px;
    width: 100%;
    z-index: 10;
    font-size: 18pt;
    color: #96c120;
    text-align: center;
    @media screen and (max-width : 767px) {
      font-size: 12pt;
    }
  }

  .prize{
    margin-top: 30px;
    margin-bottom: 20px;
    max-width: 600px;
    width: 100%;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @media screen and (max-width : 767px) {
      height: 160px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .bg{
      position: absolute;
      top: -10px;
      left: -20px;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width : 767px) {
        left:0;
        top: 0;
        background-size: 100% 100%;
        background-image:url(../images/premi-bg-1-mob.png) !important;
      }
    }
  }

  .text{
    position: absolute;
    left: 0;
    top: 50%;
    transform:translate(0,-50%);
    text-align: center;
    width: 100%;
    @media screen and (max-width : 767px) {
      padding-top:10px;
    }

    h4{
      font-size: 14pt;
      font-weight: normal;
      b{
        font-weight: 500;
      }
      @media screen and (max-width : 767px) {
        font-size: 12pt;
        padding-left:25px;
        padding-right:25px;
        br{
          display: none;
        }
      }
    }
  }
}

.reset {
  #passwords_form{
    min-height: 700px;
    input, input.error {
      border:1px solid #ddd;
      background-color:#fff;
      border-radius:10px;
      padding:6px;
      margin-bottom: 10px;
      width: 200px;
      color: #000 !important;
    }
    .button.cta {
      height: 40px;
      padding:10px;
      padding-right:40px;
      padding-left:40px;
      background: #25673e;
    }
  }
}

.win-form{
  padding-top:50px;
  min-height: 800px;

  .background {
    z-index: 0;
    opacity: 0.7;
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position:center;
    background-repeat:no-repeat;
    background-image:url(../images/vallelata21/home-vota-full.jpg);
  }
  section{
    text-align: center;
    background-color:transparent !important;
  }

  .image {
    height: 120px;
    width: 100%;
    
  }

  .container-white{
    z-index: 10;
    position:relative;
    background-color:transparent !important;
    color: #25673e;
    h4 {
      font-size: 17pt;
      font-weight: 400;
      @media screen and (max-width : 767px) {
        font-size:12pt;
      }
    }

    input.error {
      color: #25673e !important;
    }

    input {
      text-align: left;
      border:1px solid transparent;
      border-radius:3px;
      font-size: 13px;
      width: 100%;
      padding: 8px 8px;
      height: 36px;
      background-color:#ffffff;
      color: #25673e;
      border:1px solid #ddd;
      border-radius:10px;
      font-weight: 500;
    }
    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #25673e;
      opacity: 1; /* Firefox */
    }

    .btn.btn-cta {
      border-radius: 5px;
      background-color:#25673e;
      color: #ffffff;
      border:3px solid #ffffff;
    }
  }

  input{
    width: 260px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 5px;
    color:#000;
  }
}

.classifica {
  text-align: center;

  #leaderboard{
    min-height: 400px;
  }

  section{
    overflow: hidden;
  }

  h4, h3, h5{
    font-weight: normal;
  }

  h3{
    @media screen and (max-width : 767px) {
      font-size:12pt;
    }
  }

  h4{
    @media screen and (max-width : 767px) {
      font-size:10pt;
    }
  }

  .separator{
    max-width: 500px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    height: 0;
    padding-bottom: 5%;
    margin-bottom: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/catalogo-divider.png);
    @media screen and (max-width : 767px) {
      height:20px;
      padding-bottom: 0;
    }
  }

  .leaderboard-container{
    max-width: 400px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    .player{
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 4px;
      margin-bottom: 10px;
      margin-top: 10px;
      width: 100%;
      border-radius: 40px;
      height: 34px;
      font-size: 14pt;
      overflow: hidden;
      background-color: #efdfd1;

      @media screen and (max-width : 767px) {
        font-size:10pt;
        padding-top: 6px;
        height: 30px;
      }

      .name{
        max-width: 60%;
        overflow: hidden;
        white-space: nowrap;
        float: left;
        text-transform: capitalize;
      }

      .points{
        max-width: 40%;
        overflow: hidden;
        white-space: nowrap;
        float: right;
      }
    }
  }

}

.code-uploader { 

  text-align:center;
  .error-messages {
    font-weight: 500 !important;
    color: red !important;
    font-size: 12pt;
  }

  .cta.blue {
    color: #fff;
    font-size: 10pt;
    background-color:transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .cta.big {
    font-size: 12pt;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
  }

  section.first.ancors {
    margin-top: 10px;
    margin-bottom: 10px;

    .container {
      padding-left: 15px;
      padding-right: 15px;
    }

    .ancor {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      margin-top: 10px;
      text-align: left;
      background-color: #e9dec8;
      border-radius: 0;
      box-shadow: 0px 1px 5px #ddd;
      outline: none;

      .border {
        position: absolute;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        border: 1px solid #fff;
      }

      .logo {
        float: left;
        width: 55px;
        height: 42px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .logo.first {
        background-image: url(../images/code-2.png);
      }

      .logo.second {
        background-image: url(../images/code-3.png);
      }

      span {
        display: inline-block;
        font-weight: bold;
        margin-top: 12px;
      }
    }

    .ancor:hover, .ancor:active {
      box-shadow: 0px 1px 0px #ddd;
    }
  }

  section.first {

    margin-bottom:40px;

    @media screen and (max-width : 767px) {
      margin-bottom:5px;
    }

    .title {
      h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 23pt;
        font-weight: bold;
        width: 100%;
        @media screen and (max-width : 767px) {
          font-size: 14pt;
          top: 30px;

          br {
            display: none;
          }
        }
      }
      
    }

    .steps {
      padding-bottom: 20px;
    }

    .step {
      margin-right: auto;
      margin-left: auto;
      margin-top: 10px;
      padding-bottom: 30px;

      .main-title{
        .number {
          font-size: 32pt;
        }
      }
      
      h5 {
        margin-top: 20px;
        font-weight: 400;
        font-size: 11pt;
        margin-bottom: 5px;
        text-transform:uppercase;
        @media screen and (max-width : 767px) {
          font-size: 10pt;
        }
      }

      .number {
        float:left;
        height:100px;
        width:20%;
        margin-left:-5px;
        margin-right:15px;
        background-image:url(../images/step1.png);
        background-position:100% 50%;
        background-size:contain;
        background-repeat:no-repeat;
        @media screen and (max-width : 767px) {
          margin-right: 0;
          margin-left:0;
          height:70px;
          margin-bottom:-20px;
          margin-top:-20px;
          background-position:50% 50%;
          background-image:url(../images/step1-m.png);
          width:100%;
        }
      }
      .number.two {

        background-image:url(../images/step2.png);
        @media screen and (max-width : 767px) {
          background-image:url(../images/step2-m.png);
        }
      }

      .code-input {
        float:left;
        @media screen and (max-width : 767px) {
          width: 100%;
        }
        .gray {
          color: #999;
          margin-top: 10px;
        }

        .toexample {
          display: block;
          font-weight: 500;
          margin-top: 20px;
        }

        #file-input {
          position: absolute;
        }

        .name-file {
          font-size: 9pt;
          padding: 3px;
          width: 40%;
          background-color: #fff;
          margin-right: auto;
          margin-left: auto;
          border-radius: 10px;
          color: #999;
          overflow: hidden;
          margin-top: 5px;

          @media screen and (max-width : 767px) {
            width: 70%;
          }
        }

        .name-file.error {
          background: #ff5555;
          color: #000 !important;
        }

        .inputs{

          .col-xs-3, .col-xs-1 {
            padding-left: 0px;
            padding-right: 0px;
          }

          .dot {
            float: left;
            width: 12px;
            text-align: center;
            font-weight: 500;
            padding-top: 15px;
          }

          .col-xs-3{
            width: 20%;
          }

          .col-xs-1 {
            width: 13.33%;
          }

          .input {
            float: left;
            position: relative;
            width: calc(100% - 12px);
            font-weight: 500;
            outline: none;
            padding: 4px;
            padding-left: 2px;
            padding-right: 2px;
            text-align: center;
            border: none;
            border-radius: 5px;
          }
        }

        .inputs.second{ 

          .input {
            margin-left: auto;
            margin-right: auto;
            border-radius: 20px;
            width: 60%;
            font-size:10pt;
            margin-left: 20%;
            @media screen and (max-width : 767px) {
              width: 80%;
              margin-left: 10%;
              font-size:9pt;
            }
          }
        }
      }
    }

    .step.second {
      padding-top: 20px;
      margin-bottom: 20px;
      
      .code-input {

        h4 {
          margin-bottom: 5px;
        }
      }
    }

    .loose {

      padding-bottom: 80px;
      padding-left: 120px;
      padding-right: 120px;

      @media screen and (max-width : 767px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .image {
        height: 80px;
        width: 80px;
        margin-right: auto;
        margin-left: auto;
        background-size: auto 120%;
        background-position: center;
      }

      .image.one {
        background-image: url(../images/code-1.png);
      }
    }

    .container {

      .switch {

        .col{
          outline: none;
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 0;
          margin-bottom: 0;

          .topborder{
            position: absolute;
            top: 0;
            left: 10%;
            width: 80%;
            height: 5px;
            background-size: auto 138%;
            background-position: center;
            background-image: none !important;
            border-top: 1px solid #ee5468; 
          }

          .left-border {
            left: initial;
            right: 0;
            background-image: none;
            border-right: 1px solid #ee5468; 
          }
        }

        .esempio {
          text-decoration: underline;
          cursor: pointer;
        }

        .image {
          height: 80px;
          width: 80px;
          margin-right: auto;
          margin-left: auto;
          background-size: auto 100%;
          background-position: center;
        }

        .image.three {
          background-image: url(../images/code-3.png);
        }

        .image.two {
          background-image: url(../images/code-2.png);
        }
      }
    }
  }
}


button.cta {
  background-color: #ee5468;
  border-radius: 40px;
  border: none;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  font-size: 12pt;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  transition:opacity .4s ease;
  outline: none !important;
}

button.cta.disabled {
  background-color: #ccc;
}

button.cta.inverted {
  background-color: #fff;
  color: $main;
  border: 4px solid $main;
}

button.cta.inverted:hover {
  color: #fff;
  background-color: $main;
}

button {
  transition:opacity .4s ease;
}

button.cta.larger {
  padding-left: 30px;
  padding-right: 30px;
}

button:hover, button:active, button.active {
  opacity: 0.8;
}

button:active {
  opacity: 0.6;
}


.footer {
  position: absolute;
  right: 0;
  bottom: 0px;
  padding-top:35px;
  left: 0;
  height: 160px;
  background-color: #fff;
  color: #fff;
  z-index: 10;
  overflow: hidden;
  padding:0 !important;

  @media screen and (max-width : 767px) {
    height: 160px;
    bottom:-40px;
  }

  .top-footer {
    border-top:3px solid #25673e;

    .logo-image {
      width: 100%;
      height: 100px;
      background-position:center;
      background-size:auto 60%;
      background-repeat: no-repeat;
      background-image:url(../images/vallelata21/vallelata-logo.png);
    }
  }

  .bottom-footer {
    background-color:#25673e;
    padding-top:20px;
    padding-bottom: 20px;
    text-align:center;

    .footer-link.last {
      border-right:1px solid transparent
    }
    .footer-link {
      border-right:1px solid #fff;
      padding-right: 10px;
      padding-left:5px;
      display: inline-block;
    }

    .footer-link:hover {
      text-decoration: underline !important;
    }

    a:hover {
      text-decoration: underline !important;
    }
  }
}

.page-wrapper{
  position: relative;
  height: 100%;
  margin-bottom: -25px;
  @media screen and (max-width : 767px) {
    margin-bottom: 0px;
  }
}
.cntngnc {
  height: 100%;
  padding-bottom: 0;

  position: relative;
}

.contingency {
  
  margin-bottom:-60px;
  @media screen and (max-width : 767px) {
    margin-bottom: 0px;
  }
  a {
    color:$main;
  }

  .container-white {
    background: transparent;
    padding:0;
    background: rgba(255,255,255,0.7);
    overflow: hidden;
    min-height:500px;
    .home-title {
      background-image:url(../images/contingency-image.png);
      height: 400px;
      width: 90%;
      left: 5%;
      position:absolute;
      top: 100px;
    }
  }


  section.first {
    background-color:transparent !important;
    padding:0;
    .container {
      background: transparent;
    }

    .products-image {
      padding-bottom:70%;
      max-width:700px;
      height:0;
      margin-top: 40px;
      @media screen and (max-width : 767px) {
        margin-top: 140px;
        height:400px;
      }
    }
  }
  .text {
    position: relative;
    color: #000;
    z-index: 10;
    padding-top: 90px;

    @media screen and (max-width : 767px) {
      padding-top: 0px;
    }
    
    h3 {
      font-size: 14pt;
      margin-bottom: 50px;
    }

    h4{
      font-weight: normal;
    }
  }
}

@media (min-width: 768px){
  .modal-dialog {
    width: 750px;
  }
}

.info-modal {

  button.closer {
    color: #fff !important;
  }
  .border {
    border-color:transparent !important;
  }
  .modal-content {
    background-color:#25673e !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../images/vallelata21/modal-cover.jpg);
    .modal-body {
      color: #fff;
      h2 {
        margin-top: 0;
        font-family: "Chilanka", sans-serif;
        font-size: 42pt;
        @media screen and (max-width : 767px) {
          font-size: 36pt;
        }
      }
      h3 {
        font-weight: normal;

        @media screen and (max-width : 767px) {
          font-size: 12pt;
        }
      }

      p {
        line-height:150%;
        font-weight:300;
        font-size: 13pt;
      }

      .btn.cta {
        background-color:#fff !important;
        span{color: #25673e !important;}
      }

      a {
        color: #fff !important;
        font-weight: bold;
      }
    }
  }
}

.sign-modal {

  h4 {
    color:#fff;
    margin: 0;
  }

  a {
    text-decoration: underline !important;
    color: #fff !important;
  }

  .modal-content {
    background-color:#25673e !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../images/vallelata21/modal-cover.jpg);
    .modal-body {
      color: #fff;
    }
  }

  .modal-content {
    border-radius: 10px;
    border: none;
    position: relative;
    box-shadow: none;
    text-align: center;
    color: #fff;
    min-height: 250px;
    background-color: rgba(255, 255, 255, 0.9);
    @media screen and (max-width : 767px) {
      background-color: rgba(255, 255, 255, 0.9);
    }

  }

  .cta{
    @media screen and (max-width : 767px) {
      font-size:9pt;
    }
  }

  .border {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius:10px;
    border: 0px solid #25673e;
  }

  .separator {
    width: 50%;
    border: 1px solid #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .b {
    position: absolute;
    border-top: 2px solid #fff;
    top: 28px;
    width: 34%;
    @media screen and (max-width : 767px) {
      width: 20%;
    }
  }

  .b.left {
    left: 28px;
  }

  .b.right {
    right: 28px;
  }

  .top-logo {
    position: absolute;
    top: 10px;
    width: 110px;
    height: 40px;
    background-image: url(../images/carrefour-spesa-button-white.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-dialog {
    margin-top: 100px;
    @media screen and (max-width : 767px) {
      margin-top:10px;
    }
  }

  .modal-body {
    padding: 40px;

    .input {
      width: 100%;
      border: none;
      padding: 5px;
      color: #25673e;
      border-radius: 8px;
      padding-left:8px;
      padding-right:8px;
      background-color:#fff;
      margin-top: 15px;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #25673e;
      opacity: 0.5; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #25673e;
      opacity: 0.5;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #25673e;
      opacity: 0.5;
    }

    .row {
     
    }
  }

  .facebook {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top:25px;

    .facebook-btn {
      border: none;
      background-color: $facebook;
      outline: 0;
      opacity: 1 !important;
      height: 30px;
      width: 180px;
      background-image: url(../images/fb-button.jpg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      text-align: right;
      padding-right: 15px;

      span {
        transition:opacity .3s ease;
        color: #fff;
        font-size: 10pt;

        b {
          font-weight: 500;
        }
      }
    }

    .facebook-btn:hover {
      span {
        opacity: 0.8;
      }
    }
  }

  .or {
    margin-top: 5px;
  }

  button.cta {
    
    background-color: #fff !important;
    padding-top: 5px;
    border:none;
    padding-bottom: 5px;
    padding-left: 25px;
    font-size: 15pt;
    border-radius:8px;
    font-weight: normal;
    padding-right: 25px;
    span {
      color: #25673e !important;
    }
  }

  a {
    color: #fff !important;
    text-decoration: underline;
  }

  h6, .checkbox {
    font-weight: normal;
    font-size: 9pt;
  }

  .checkbox.withradio {
    margin-bottom: 0;
  }

  .checkbox {
    input {
      margin-top: 0;
    }
  }

  .radio {
    margin-top: 0;
    .radio-inline {
      font-size: 9pt;
    }
    input {
      margin-top: 0;
    }
  }

  button.closer {
    z-index: 100;
    font-size: 25pt;
    outline: none;
    color: #fff;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 5px;
    width: 35px !important;
    height: 35px;
    background-color: transparent;
    border:none;
  }
}


body.ios {
  cursor:pointer;
}

body.uploader {

  @media screen and (max-width : 767px) {
     background-color: $bg-light;
  }
}

section.tool {

  .tool-container {
    text-align: center;
    color: #000;
    max-width: 900px !important;
    width: 100% !important;
    margin-top: 20px;
    box-shadow: 0px 10px 40px rgba(100,100,100,0.1);
    background-color: rgba(255,255,255,0.8);
    padding: 40px;
    padding-top: 40px;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 40px;

    @media screen and (max-width : 767px) {
      min-height:550px;
      padding-top: 40px;
      box-shadow: none;
      padding-right:0px !important;
      padding-left: 0px !important;
      
      .row {
        margin: 0;
      }
    }

    .bg {
      position: absolute;
      background-size:contain;
      background-repeat: no-repeat;
      z-index: 0;
    }

    .cta {
      z-index: 1;
      position: relative;
    }

    .bg.top {
      height: 280px;
      width: 30%;
      top: 0px;
      left: -10px;
      background-position: 0% 0;
      background-image: url(../images/personaggio1.png);

      @media screen and (max-width : 991px) {
        height:200px;
        bottom: 30px;
      }

      @media screen and (max-width : 767px) {
        top:initial;
        width:100%;
        bottom:20px;
        left:0;
        background-position:center;
      }
    }

    .bg.bottom {
      height: 260px;
      width: 30%;
      top: 30%;
      right: -20px;
      background-position: 0% 0;
      background-image: url(../images/personaggio2.png);
      @media screen and (max-width : 991px) {
        height:180px;
        top: 35%;
        right: -35px;
        background-position: 50% 0;
      }
    }

    .title {
      position: relative;
      margin-right: auto;
      margin-left: auto;
      max-width: 400px;

      @media screen and (max-width : 767px) {
        max-width: 250px;
      }

      h4 {
        font-weight: normal;
        font-size: 12pt;
      }
    }

    .flag {
      position: relative;
      color: $main;
      margin-right: auto;
      margin-left: auto;
      max-width: 55%;

      @media screen and (max-width : 767px) {
        margin-bottom:20px;
        margin-top: 20px;
      }


      input {
        
        @media screen and (min-width : 992px) {
          margin-left: -27px;
          margin-top: 10px;
        }
      }

      @media screen and (max-width : 991px) {
        max-width:320px;
      }

      a {
        text-decoration: underline;
      }

      label {
        font-size: 8pt;
      }
    }

    .t-containers {
      
      @media screen and (max-width : 767px) {
        position:fixed;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10000;
        transform:translate(0px,100%);
      }

      .tool-header{
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1000;
        background-color: #dcf0f3;
        height: 40px;
        transition:transform 0.4s ease;
        transform:translate(0px,-40px);

        .left {
          position: absolute;
          left: 10px;
          width: 25px;

          .button-close {
            padding: 0;
            padding-left: 10px;
            padding-right: 10px;
            font-weight: bold;
            margin-top: -5px;
            border: none;
            background-color: transparent;
            color: #74c6cc;
            font-size: 22pt;
            transform:scaleX(1.2);
          }
        }

        .right {
          position: absolute;
          right: 10px;
          text-align: right;

          .separator{
            float: left;
            height: 26px;
            background-color: #74c6cc;
            width: 2px;
            border-radius: 2px;
            margin-top: 7px;
          }

          .button {
            float: left;
            padding: 0;
            font-weight: 500;
            margin-top: -3px;
            color: #74c6cc;
            font-size: 13pt;
            transform:scaleX(1.2);
          }

          .button-face {
            width: 40px;
            height: 40px;
            margin-right: 5px;
            margin-left: 5px;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 0px;
            background-size: 30px 30px;
            border: none;
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../images/smile.png);
          }

          .button-save {
            border: none;
            background-color: transparent;
            text-align: left;
            width: 70px;
          }
        }
      }
    }

    .t-container {
      float: left;
      height: 460px;

      @media screen and (max-width : 767px) {
        display:block;
        height: 100%;
        width: 100%;
        position: absolute;
        padding: 0 !important;

        .box {

          width: 100% !important;
        }
      }

      .box {
        height: 100%;
        background-color: #fff;
      }
    }

    .preview {
      padding: 10px;
      margin-left: 50px;

      @media screen and (max-width : 991px) {
        margin-left:20px;
      }

      @media screen and (max-width : 767px) {
        margin-left:0px;
        z-index:100;
        transition:transform 0.4s ease;
        transform:translate(0px,100%);
      }
      
      .box {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/tool-icons/carica-qui.png);
        width: 250px;
        position: relative;
        overflow: hidden;

        @media screen and (max-width : 767px) {
          background-color:#fff;
          margin-bottom: 20px;
          overflow: auto;

          .tool-img-wrap {
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            right: 0;
            top: 40px;
            bottom: 0px;
            margin-right: auto;
            margin-left: auto;
          }
        }

        .left-handle {
          position: absolute;
          height: 100%;
          width: 30px;
          left: 0;
          top: 0;
          z-index: 10000;
          display:none;

          @media screen and (max-width : 767px) {
            display:block;
          }
      
        }
        
        .right-handle {
          position: absolute;
          height: 100%;
          width: 30px;
          right: 0;
          top: 0;
          z-index: 10000;
          display:none;

          @media screen and (max-width : 767px) {
            display:block;
          }
        }

        canvas {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 8;
          height: 100%;
        }

        #image-canvas {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .resize-drag {
            color: transparent;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -50px;
            width: 100px;
            height: 100px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 5px;
            border: 4px solid transparent;
            z-index: 10000;
            cursor: move;

            .image {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }

            .border {
              position: absolute;
              top: 0;
              opacity: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: 100%;
              width: 100%;
              border: 4px solid $bg;    
              border-radius: 2px;
            }
          }

          .resize-drag.on {
            
            .border {
              opacity: 1;
            }

            .handle {
              display: block;
            }
          }

          .resize-drag:hover {
            border: 4px solid transparent;
          } 
        }

        .handle {
          display: none;
          cursor: pointer;
          position: absolute;
          height: 30px;
          width: 30px;
          border-radius: 50px;
          background: $main;
        }

        .rotate-handle {
          right: -15px;
          bottom: -15px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 60% 60%;
          background-image: url(../images/rotate-32.png);
        }

        .resize-handle {
          left: -15px;
          top: -15px;
          
          background-position: center;
          background-repeat: no-repeat;
          background-size: 60% 60%;
          background-image: url(../images/resize-32.png);
        }

        .foreground {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 9;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          pointer-events: none;
          background-image: url(../images/tool-icons/foreground.png);
        }

        .trash {
          position: absolute;
          left: 50%;
          bottom: 10px;
          width: 80px;
          border:2px dashed #ccc;
          height: 50px;
          background-color: #fff;
          margin-left: -40px;
          border-radius: 10px;
          z-index: 15;
          opacity: 0;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          pointer-events: none;
          background-image: url(../images/trash.png);

          @media screen and (max-width : 767px) {
            bottom:initial;
            top: 10px;
            width: 50px;
            height: 40px;
            margin-left: -25px;
          }
        }

        .trash.drop-active {
          background-color: #e94d43;
        }

        .trash.on {
          opacity: 0.75;
        }
      }
    }

    .t-container.save {
      @media screen and (max-width : 767px) {
        display:none;
      }
    }

    .save {
      padding: 10px;
      position: relative;
      width: 240px;

      @media screen and (max-width : 991px) {
        width:120px;
      }

      .cta {
        position: relative;
        top: 50%;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: -20px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .emojis {
      padding: 10px;
      margin-left: 10px;

      @media screen and (max-width : 767px) {
        margin-left:0px;
        z-index:101;
        top: 40px;
        transition:transform 0.4s ease;
        transform:translate(0px,100%);
      }

      .box {
        position: relative;
        box-shadow: 0px 10px 20px rgba(100,100,100,0.05);
        width: 300px;
        border-radius: 10px;
        border: 6px solid #fff;
        background-color: rgb(245,252,254);

        @media screen and (max-width : 767px) {
          background-color: rgba(245,252,254,0.85);
          border: none;
        }

        .intro {
          position: relative;
          background-color: rgb(245,252,254);
          padding-top: 5px;
          font-weight: normal;
          margin-top: 0;
          font-size: 9pt;
          z-index: 1;
          @media screen and (max-width : 767px) {
            font-weight:500;
          }
        }

        .emoji-container {
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px;
          padding-top: 40px;
          height: 100%;
          width: 100%;
          overflow: auto;
          -webkit-overflow-scrolling: touch;

          .wrapper {
            border: 1px solid $bg;
            width: 100%;
            display: table;

            @media screen and (max-width : 767px) {
              border:none;
            }

            .item {
              width: 20%;
              padding: 5px;
              padding-top: 5px;
              background-size: 80% auto;
              background-position: center;
              background-repeat: no-repeat;
              float: left;
              cursor: pointer;
              position: relative;

              img {
                width: 80%;
                margin-left: 5%;
              }

              .sprite {
                width: 100%;
                padding-bottom: 100%;
                display: block;
              }
            }

            .item.big {
              width: 100%;

              .sprite {
                padding-bottom: 8%;
              }
            }

            .item.bigl {
              width: 100%;
              
              .sprite {
                padding-bottom: 20%;
              }
            }

            .item:hover {
              background-color: $bg;
            }
          }
        }
      }
    }

    .onimage {
      transform:translate(0px,0px);
      .tool-header {
        opacity: 1;
        transform:translate(0px,0px);
      }

      .preview {
        transform:translate(0px,0px);

        .box {
          
        }
      }
    }

    .onemojis {

      .emojis {
        transform:translate(0px,0px);

        .box {
          
        }
      }
    }
  }
}

.ca-code {

  .inside {
    max-width: 84%;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width : 767px) {
      max-width: 100%;
    }

    .code {
      width: 100%;
      padding: 5px;
      background-color: #fff;
      color: $main;
      font-weight: 500;
      font-size: 20pt;
      text-transform: uppercase;
    }

    .conditions {
      margin-top: 15px;
      width: 100%;
      text-align: center;
      background-color: #fff;
      height: 180px;
      overflow: auto;
      color: #000;
      margin-bottom: 20px;
      font-weight: 500;
      padding-top: 10px;

      .text {
        font-weight: normal;
        font-size: 9pt;
        padding: 10px;
      }
    }
  }
}

.thankyou {

  .container.tool-container{
    padding-top: 150px;
    padding-bottom: 150px;
    @media screen and (max-width : 767px) {
      padding-top: 50px;
      padding-bottom: 200px;
    }
  }

  .content {
    position: relative;

    h3 {
      @media screen and (max-width : 767px) {
        font-size:12pt;
      }
    }

    h4 {
      margin-top: 30px;
      font-weight: normal;
      max-width: 250px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  
  .back{
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/thankyou.png);
    @media screen and (max-width : 767px) {
      background-size: 900px auto;
      background-position: 40px 110px;
    }
  }
}

body.gallery {
  background-color: $bg-superlight;
  
  .page-wrapper {
    padding-bottom: 100px;
  }

  .load-more {
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    top: -100px;
  }

  section.third {
    background-color: $bg-superlight;

    .pic-header {
      position: relative;
      height: 140px;

      .title {
        padding-top: 60px;
      }

      .bg {
        position: absolute;
        height: 100%;
        top: 0;
        width: 200px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @media screen and (max-width : 767px) {
          display:none;
        }
      }

      .bg.left {
        left: 0;
        background-image: url(../images/personaggio1.png);
      }

      .bg.right {
        right: 0;
        background-image: url(../images/personaggio2.png);
      }
    }

    .loading {
      background: rgba(58,104,61,0.4);
      width: 180px;
      border-radius: 40px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;

      img {
        width: 100%;
      }
    }

    .pics {

      .pic {
        background-color: $bg-light;
      }
    }
  }

  .pic-modal {

    .modal-content {
      background-color: rgba(14, 22, 49, 0.7);
      margin-top: 120px;
      border-radius: 10px;
      min-height: 200px;

      .arrow {
        position: absolute;
        top: 50%;
        margin-top: -25px;
        height: 50px;
        width: 50px;
        background-color: #fff;
        border: none;
        color: #aecad1;
        font-size: 22pt;
        border-radius: 50px;
        outline: none;
        span {
          display: block;
          margin-top: -10px;
          transform:scaleY(1.7);
        }
      }

      .arrow.left {
        left: 10%;
      }

      .arrow.right {
        right: 10%;
      }

      .nome {
        text-align: center;
      }

      .pic {
        color: #000;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .close-btn {
        position: absolute;
        right: 10px;
        top: 0px;
        background-color: transparent;
        border: none;
        font-size: 30pt;
        color: #aecad1;
        transform:scaleX(1.2);
      }
    }

  }
}

.priv-policy{

  .container.tool-container{
    max-height: 500px;
    overflow: auto;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.fade{
  transition: opacity .2s linear;
}

.modal.fade {

  .modal-dialog {
    transform: scale(0.95);
    opacity: 0;
    transition: all 0.3s;
  }
}

.modal.fade.in {

  .modal-dialog {
    transform: scale(1);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

#loading{
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(58,104,61,0.4);

  img {
    opacity: 0.6;
    background-color: transparent;
    border-radius: 40px;
    width: 200px;
    margin-left: -100px;
    margin-top: -60px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

input.error {
  border: 2px solid darkred !important;
  background: #ffeeee;
  outline: none;
}
select.error {
  border: 2px solid darkred !important;
  background: #ffeeee;
  outline: none;
}
label.error {
  padding: 2px;
  color: #ff5555 !important;
  outline: none;
}
.error-messages {
  color: darkred;
}
.e-msg.error{
  color: darkred;
  background: transparent;
}

.cb{
  position:fixed;
  background:#25673e;
  bottom:0;
  left:0;
  right:0;
  widht:100%;
  padding:40px;
  color:#fff;
  opacity:0.85;
  z-index:10000;
  text-align:center;

  @media screen and (max-width : 991px) {
    padding-bottom:80px;
    padding-top:20px;

  }

  br {
    @media screen and (max-width : 991px) {
      display: none;
    }
  }

  .text {
    font-size:10pt;
    @media screen and (max-width : 767px) {
      font-size:9pt;
    }
  }

  .close-btn {
    position:absolute;
    top:50%;
    transform: translate(0, -50%);
    right:20px;
    font-size:14pt;
    font-weight:bold;
    background-color: #fff;
    color: #25673e;
    padding:5px;
    padding-left:20px;
    padding-right:20px;
    border:none;
    border-radius:4px;
    cursor:pointer;

    @media screen and (max-width : 991px) {
      transform: translate(50%, 0%);
      right: 50%;
      bottom: 30px;
      width: 80%;
      top: unset;
      max-width: 400px;
    }
  }

  a {
    color: #fff !important;
    text-decoration: underline !important;
    font-weight:bold;
  }
}


body.in-myc4 {

  .main-bg {
    //height: 100% !important;
    top:0 !important;
  }

  .sign-modal {
    .modal-dialog {
      margin-top: 110px;
      @media screen and (max-width : 767px) {
        margin-top:110px;
      }
    }
  }

  .full-game {
    .time-container, .button-close {
      top:30px !important;
    }

    .play-container {
      @media only screen and (min-width : 375px) and (min-height : 730px) { 
        bottom: 100px !important;
      }
    }
    .stars-container {
      @media only screen and (min-width : 375px) and (min-height : 730px) { 
        bottom: 100px !important;
      }
    }
  }

  .scopri-di-piu {
    display:none;
  }

  .tizio-cont {
    display:none;
    bottom:0;
    .tizio {
      top:0;
      display:none;
    }
  }

  .rays {
    display:none;
  }

  .footer {
    display:none;
  }
  .in-app-footer {
    display:table;
    width:100%;
    margin-top:-40px;
    .footer {
      position:relative;
      display:block;
      height:260px;
      bottom:0px;
      top:0;
      .link {
        display:none;
      }
      .container {
        padding-top:30px;
        height:240px;
      }
    }
  }

  .page-wrapper {
    position:fixed;
    padding-top:100px;
    top: 0;
    right:0;
    bottom:0;
    left: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

body.in-myc4.game-wrap {
  .page-wrapper {
    padding-top:0 !important;
  }
}

#iframe {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.modal .btn.cta-social.full {
  padding-left: 20px;
  padding-right: 20px;
}

.modal .btn.cta-social {
  margin-top: 20px;
  position:relative;
  padding:10px;
  padding-left:50px;
  border:2px solid white;
  border-radius: 8px;
  font-size: 13pt;
  text-transform: uppercase;
  background-color:#98cc3c !important;
  margin-right: 10px;

  i {
    position:absolute;
    top:50%;
    left: 3px;
    width: 34px;
    height: 34px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform:translate(0,-50%);
  }

  .fb-icon {
    background-image:url(../images/vallelata21/fb-icon.png);
  }

  .wh-icon {
    background-image:url(../images/vallelata21/wh-icon.png);
  }
}

.recaptcharecaptcha > div {
  margin:auto;
}